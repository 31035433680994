import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from '@assets/images/icons/closeIcon.svg';
import {
  Wrapper,
  ShadowLayer,
  ContentWrapper,
  CloseButton,
} from '@components/Modal/style';

export const Modal = ({ isOpenModal, children, onClose, version }) =>
  isOpenModal && (
    <Wrapper>
      <ShadowLayer onClick={onClose} />
      <ContentWrapper version={version}>
        {children}
        <CloseButton
          variant="ghost"
          onClick={onClose}
          _hover={{
            bg: 'transparent',
          }}
          icon={<img src={CloseIcon} alt="" />}
        />
      </ContentWrapper>
    </Wrapper>
  );

Modal.defaultProps = {
  version: '',
};
Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  version: PropTypes.string,
};

export default Modal;
