import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box } from '@chakra-ui/react';
import Banner from '@components/Banner';
import Preloader from '@components/Preloader';
import { PageSection, StaticContent } from '@styles/globalStyles';

const Faq = ({ staticsStore: { statics, getFaq } }) => {
  useEffect(() => {
    getFaq();
  }, [getFaq]);

  const createMarkup = () => ({
    __html: statics.faq.body,
  });

  return (
    <>
      <Banner title={statics.faq.title || 'FAQ'} />
      <PageSection mt="40px">
        {statics.faq.subtitle && (
          <Box mb="34px" as="h6">
            {statics.faq.subtitle}
          </Box>
        )}
        {statics.isLoading && <Preloader />}
        <StaticContent dangerouslySetInnerHTML={createMarkup()} />
      </PageSection>
    </>
  );
};

Faq.propTypes = {
  staticsStore: PropTypes.shape({
    statics: PropTypes.object.isRequired,
    getFaq: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('staticsStore')(observer(Faq));
