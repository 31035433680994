import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const COLORS = new Map();
COLORS.set('white', '#fff');
COLORS.set('black', '#1d1d1f');
COLORS.set('light-aqua', '#a4f0ed');
COLORS.set('aqua', '#328784');
COLORS.set('dark-aqua', '#2b7a77');
COLORS.set('emph-aqua', '#88c5c3');
COLORS.set('light-blue', '#def2f1');
COLORS.set('grey', '#adbec0');
COLORS.set('dark-grey', '#606065');
COLORS.set('error', '#D8000C');

const overrides = {
  colors: {
    primary: COLORS.get('black'),
    secondary: COLORS.get('white'),
    emph: COLORS.get('dark-aqua'),
    emphLight: COLORS.get('light-aqua'),
    grey: COLORS.get('dark-grey'),
    hover: COLORS.get('emph-aqua'),
    error: COLORS.get('error'),
    aqua: {
      50: '#def2f1',
      100: '#c0eceb',
      200: '#a4f0ed',
      300: '#88c5c3',
      400: '#328784',
      500: '#2b7a77',
      600: '#22615f ',
      700: '#194947',
      800: '#11302f',
      900: '#0c2423',
    },
    bgLight: '#E4F6F6',
  },
  backgrounds: {
    pageContent: COLORS.get('white'),
    primary: COLORS.get('dark-aqua'),
    secondary: COLORS.get('light-blue'),
    error: '#FFBABA',
  },
  shadows: {
    primary: `
    0 1px 1px rgba(0, 0, 0, 0.07),
    0 2px 2px rgba(0, 0, 0, 0.07),
    0 4px 4px rgba(0, 0, 0, 0.07),
    0 8px 8px rgba(0, 0, 0, 0.07),
    0 16px 16px rgba(0, 0, 0, 0.07);
  `,
  },
  borders: {
    primary: COLORS.get('dark-aqua'),
    secondary: COLORS.get('aqua'),
    tertiary: COLORS.get('grey'),
    error: COLORS.get('error'),
  },
  page: {
    pageWidth: '1400px',
    sidePadding: '20px',
  },
  navBar: {
    height: {
      desktop: '83px',
      mobile: '102px',
    },
  },
  footer: {
    height: {
      desktop: '143px',
      mobile: '220px',
    },
  },
  banner: {
    height: {
      small: {
        desktop: '194px',
        mobile: '116px',
      },
      medium: {
        desktop: '395px',
        mobile: '393px',
      },
      large: {
        desktop: '617px',
        mobile: '451px',
      },
    },
  },
  transitionColor: 'color 0.2s ease',
  transitionAll: 'all 2s ease',
  components: {
    Button: {
      baseStyle: {
        fontWeight: '500',
        borderRadius: '4px',
      },
      variants: {
        outline: {
          borderWidth: '2px',
        },
      },
    },
    Container: {
      baseStyle: {
        maxW: '1440px',
        px: '20px',
      },
    },
  },
};

const theme = extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: 'aqua', components: ['Button'] }),
);

export default theme;
