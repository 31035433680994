import React from 'react';
import { Link } from 'react-router-dom';
import NewBanner from '@components/Banner/newBanner';
import { NewBannerTitle } from '@components/Banner/style';
import {
  Section,
  Title,
  BookMeetingBanner,
  BannerCTA,
} from '@containers/ProcurementService/style';
import Card from '@components/Card';
import ValueBanner from '@components/ProcurementValues';

import { Flex, Box, Image, Text, Button, Heading } from '@chakra-ui/react';

import data from '@containers/ProcurementService/tiers.json';
import { procurementValues } from '@containers/ProcurementService/values';
import BANNER from '@assets/images/banners/procurementService.png';
import BookMeetingBANNER from '@assets/images/banners/bookMeetingBanner.png';

import useBreakpoint from '@hooks/useBreakpoint';

const ProcurementService = () => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isSmallerDesktop = matchBreakpoint(BREAKPOINTS.LG);

  return (
    <>
      <NewBanner
        size="large"
        title={<NewBannerTitle>SUPPORTING YOUR FM PROCUREMENT</NewBannerTitle>}
        image={BANNER}
        subtitle="BuyerSupplier manage the full procurement process from taking the original brief through to recommending the  best supplier"
        button={null}
      />
      <Box bg="#def2f1">
        <Section>
          <Title>
            Efficient, professional service for a fixed good value fee
          </Title>
          <Flex
            sx={{ gap: '24px' }}
            mt="48px"
            flexDirection={isSmallerDesktop ? 'column' : 'row'}
          >
            {data.tiers.map(x => (
              <Card key={x.id} {...x} />
            ))}
          </Flex>
          <Text
            fontSize="16px"
            lineHeight="18px"
            fontWeight="400"
            marginTop="20px"
          >
            For services costing more than £500,000 pa please contact us to
            discuss your requirements.
          </Text>
        </Section>
      </Box>

      <BookMeetingBanner>
        <Image src={BookMeetingBANNER} alt="" />
        <BannerCTA>
          <Text
            fontSize="32px"
            fontWeight={600}
            align="right"
            fontFamily="'Nunito', sans-serif"
          >
            Book a free introductory meeting with us
          </Text>
          <Button
            as={Link}
            to={{
              pathname: 'https://calendly.com/buyersupplier/',
            }}
            target="_blank"
            rel="noopener noreferrer"
            flex={1}
            variant="outline"
            height="100%"
            minH="64px"
            maxW="556px"
            w="100%"
            fontSize="24px"
            color="#4C9F81"
            borderColor="#4C9F81"
            align="center"
            whiteSpace="pre-wrap"
            fontFamily="'Nunito', sans-serif"
            fontWeight="600"
          >
            Book a free introductory meeting
          </Button>
        </BannerCTA>
      </BookMeetingBanner>
      <Box sx={{ '.banner:nth-of-type(2)': { my: '72px' } }}>
        {procurementValues.map(x => (
          <ValueBanner key={x.id} {...x} />
        ))}
      </Box>
      <Box bg="#D5E4E4" mt={['60px', '120px']}>
        <Section fontFamily="'Nunito', sans-serif">
          <Heading fontWeight="600" as="h3" color="#2b7a77" fontSize="48px">
            The Fees
          </Heading>
          <Text
            mt="32px"
            mb="64px"
            fontWeight="600"
            lineHeight="42px"
            fontSize="32px"
          >
            Free upfront introductory meeting where we agree your requirements
          </Text>
          <Button
            as={Link}
            to={{
              pathname: 'https://calendly.com/buyersupplier/',
            }}
            target="_blank"
            rel="noopener noreferrer"
            flex={1}
            height="100%"
            variant="outline"
            minH="64px"
            maxW="556px"
            w="100%"
            fontSize="24px"
            bg="#fff"
            color="#4C9F81"
            borderColor="#4C9F81"
            align="center"
            whiteSpace="pre-wrap"
          >
            Book a meeting
          </Button>
          <Text my="64px" fontSize="24px" lineHeight="32px" fontWeight="600">
            Following instruction to proceed 30% fee due; 70% on issue of tender
            report
          </Text>
          <Text fontWeight="600" fontSize="32px" lineHeight="32px">
            What is not included?
          </Text>
          <Text my="32px" fontSize="24px" lineHeight="32px" fontWeight="600">
            If you do not have the information needed to tender such as asset
            registers or floor areas and we need to <br /> have separate site
            visits to develop this information for you.
          </Text>
          <Text fontSize="24px" lineHeight="32px" fontWeight="600">
            Any legal advice on contract terms and conditions
          </Text>
        </Section>
      </Box>
    </>
  );
};

export default ProcurementService;
