import styled from '@emotion/styled/macro';
import { Box } from '@chakra-ui/react';
import { breakpoint } from '@styles/breakpoints';

const PageSectionCalcWidth = ({ theme }) =>
  `1226px + 2 * ${theme.page.sidePadding}`;

export const Section = styled(Box)`
  max-width: calc(${PageSectionCalcWidth});
  margin: 0 auto;
  padding: 120px 20px;
`;

export const Title = styled.h2`
  color: #2b7a77;
  width: 100%;
  font-size: 48px;
  font-weight: 600;

  ${({ align }) => `
    text-align: ${align}
  `};
`;

export const BookMeetingBanner = styled(Box)`
  padding: 120px 20px 120px 0;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) =>
    `calc(${`1400px + 2 * ${theme.page.sidePadding}`})`};
  display: flex;
  gap: 32px;

  @media ${breakpoint.xl} {
    img {
      max-width: 500px;
      width: 40%;
    }
  }

  @media ${breakpoint.lg} {
    display: block;
    padding: 60px 0;

    img {
      max-width: unset;
      width: 100%;
    }
  }
`;

export const BannerCTA = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  gap: 64px;
  align-self: center;

  @media ${breakpoint.lg} {
    padding: 50px;
    text-align: center;

    p {
      text-align: center;
    }
  }

  @media ${breakpoint.md} {
    padding: 40px 20px;
    text-align: center;

    p {
      text-align: center;
    }
  }
`;
