import React from 'react';
import PropTypes from 'prop-types';
import InfoBoxDetailed from '@components/InfoBoxDetailed';
import PLACEHOLDER from '@assets/images/suppliers/placeholder.png';
import { Container } from '@containers/Profile/components/ProfileSupplier/style';

const ProfileSupplier = ({
  email,
  supplier: { company, description, id, locations, logo, address },
}) => (
  <Container>
    <InfoBoxDetailed
      company={company}
      description={description}
      email={email}
      id={id}
      location={locations?.map(el => el.label).join(', ')}
      logo={logo || PLACEHOLDER}
      companyAddress={address}
    />
  </Container>
);

ProfileSupplier.defaultProps = {
  address: null,
  email: null,
};

ProfileSupplier.propTypes = {
  address: PropTypes.string,
  email: PropTypes.string,
  supplier: PropTypes.shape({
    company: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    locations: PropTypes.array,
    logo: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
};

export default ProfileSupplier;
