import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image } from '@chakra-ui/react';

const ServiceTile = ({ logo, label }) => (
  <Box
    borderRadius="4px"
    mx="20px"
    position="relative"
    _hover={{
      '.label': { fontSize: '22px', transition: 'font-size .4s' },
      '.image': { filter: 'grayscale(0.6)', transition: 'filter .4s' },
    }}
  >
    <Image
      src={logo}
      alt=""
      className="image"
      filter="unset"
      transition="filter .4s"
      width="100%"
    />
    <Box
      className="label"
      bg="#def2f1"
      position="absolute"
      bottom="0"
      width="100%"
      left="0"
      padding="8px 16px"
      transition="font-size .4s"
      fontFamily="'Roboto', sans-serif"
    >
      {label}
    </Box>
  </Box>
);

ServiceTile.propTypes = {
  logo: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ServiceTile;
