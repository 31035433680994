import OFFICE from '@assets/images/banners/office3.png';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import Banner from '@components/Banner';
import FormMessage from '@components/Form/FormMessage';
import { Container } from '@containers/AboutUs/style';
import { ContentLeft, ContentRight } from '@containers/ContactUs/style';
import SignInForm from '@containers/SignIn/components/SignInForm';
import useBreakpoint from '@hooks/useBreakpoint';
import routes from '@routes';
import { MediumHeading } from '@styles/globalStyles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const SignInPage = ({ authStore: { signIn, isLoading } }) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const { search } = useLocation();

  const isConfirmed =
    new URLSearchParams(search).get('account_confirmation_success') === 'true';

  return (
    <>
      <Banner title="Log In" />
      <Container>
        <Flex flexWrap="wrap">
          <ContentLeft>
            <MediumHeading mb="32px">
              Welcome back to BuyerSupplier
            </MediumHeading>
            <Box
              width={{
                base: 'full',
                lg: '95%',
                xl: '85%',
              }}
            >
              {isConfirmed && (
                <FormMessage
                  message="Your account has been activated. You can log in now."
                  state="success"
                />
              )}
              <SignInForm onSubmit={signIn} isLoading={isLoading} />
              <Box mt="32px" pt="32px" borderTop="1px solid #adbec0">
                <Button
                  as={Link}
                  to={routes.signUp}
                  w="full"
                  minH="56px"
                  variant="outline"
                >
                  Register
                </Button>
              </Box>
            </Box>
          </ContentLeft>

          {!isMobile && (
            <ContentRight>
              <Image src={OFFICE} alt="" />
            </ContentRight>
          )}
        </Flex>
      </Container>
    </>
  );
};

SignInPage.propTypes = {
  authStore: PropTypes.object.isRequired,
};

export default inject('authStore')(observer(SignInPage));
