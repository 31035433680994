import styled from '@emotion/styled/macro';
import { Box } from '@chakra-ui/react';
import { EmphContainer } from '@containers/AboutUs/style';
import { breakpoint } from '@styles/breakpoints';

export const ContentLeft = styled(Box)`
  padding: 70px 56px 120px 0;
  width: 50%;
  font-size: 26px;
  line-height: 1.2em;
  @media ${breakpoint.xl} {
    padding-right: 40px;
  }
  @media ${breakpoint.md} {
    width: 100%;
    padding: 60px 10px 20px;
    p {
      text-align: center;
    }
  }
`;

export const ContentRight = styled(Box)`
  position: relative;
  padding: 70px 0 0;
  width: 50%;
  font-size: 26px;
  line-height: 1.2em;
  img {
    border-radius: 60px 4px 4px;
    position: relative;
    top: -140px;
    margin-bottom: -26px;
    box-shadow: ${({ theme }) => theme.shadows.primary};
    @media ${breakpoint.md} {
      position: static;
      top: 0;
      margin-bottom: 70px;
    }
  }
  @media ${breakpoint.md} {
    width: 100%;
    padding: 35px 10px 0;
  }
`;

export const EmphContainerStyled = styled(EmphContainer)`
  padding: 51px 61px 50px 77px;
  h3 {
    padding-right: 20px;
    font-size: 32px;
    @media ${breakpoint.xl} {
      padding-right: 0;
    }
  }
  @media ${breakpoint.xl} {
    margin: 0;
  }
`;
