export const TURNOVER_OPTIONS = [
  '0-£250k',
  '£250k-£1m',
  '£1m-£5m',
  '£5m-£20m',
  '£20m+',
  'Not known',
];

export const PROJECT_VALUE_OPTIONS = [
  '£0-£5k',
  '£5k-£25k',
  '£25k-£100k',
  '£100k-£250k',
  '>£250k',
];

export const WORKSTATIONS_OPTIONS = [
  '0-5',
  '5-25',
  '25-100',
  '100-250',
  '>250',
];

export const PRICE_POINT_OPTIONS = [
  {
    label: 'Low Price Point',
    value: 'Low Price Point',
    tooltip: 'Less than £300 for a desk and chair',
  },
  {
    label: 'Medium Price and quality',
    value: 'Medium Price and quality',
    tooltip: 'Between £300 and £600 for a desk and chair',
  },
  {
    label: 'High price and quality',
    value: 'High price and quality',
    tooltip: 'Greater than £600 for a desk and chair',
  },
];

export const STOCK_OPTIONS = ['0-5', '5-25', '25-100'];

export const SECOND_HAND_OPTIONS = [
  'We provide new furniture only ',
  'We provide second hand and new furniture',
  'We provide second hand furniture only ',
];

export const LOCATION_TYPES_OPTIONS = [
  {
    label:
      'National (tick if you covered more than 5 UK regions on a single project in the past three years)',
    value: '1',
  },
  {
    label:
      'International (tick if you have delivered to countries outside of the UK in the past 3 years)',
    value: '2',
  },
];

export const RELATED_SERVICES_OPTIONS = [
  'Shop Fit out',
  'Office Fit out',
  'Audio Visual Installation',
  'Design and Build',
];
