import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Flex, Image, Text } from '@chakra-ui/react';
import about1 from '@assets/images/banners/about1.png';
import about2 from '@assets/images/banners/about2.png';
import about3 from '@assets/images/banners/about3.png';
import Banner from '@components/Banner';
import { ABOUTUS } from '@containers/AboutUs/data';
import {
  Container,
  ContentLeft,
  ContentRight,
  EmphContainer,
  LinkStyled,
  Section,
} from '@containers/AboutUs/style';
import useBreakpoint from '@hooks/useBreakpoint';
import routes from '@routes';
import { PageSection } from '@styles/globalStyles';

const temp = {
  about1,
  about2,
  about3,
};

const AboutUs = () => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  return (
    <>
      <Banner title="About us" />
      {ABOUTUS.map((item, itemIndex) => (
        <Section key={`item-${itemIndex}`} even={item.even}>
          <Container as={Flex}>
            <ContentLeft first={item.first}>
              {item.contents?.map((contentLeft, contentLeftIndex) => (
                <React.Fragment key={`content-${contentLeftIndex}`}>
                  {contentLeft.header && (
                    <Text
                      as="h2"
                      fontWeight="600"
                      fontSize="28px"
                      mb="30px"
                      mt={contentLeftIndex > 0 ? '100px' : 0}
                      lineHeight="inherit"
                    >
                      {contentLeft.header}
                    </Text>
                  )}
                  {contentLeft.paragraphs?.map(
                    (paragraphLeft, paragraphLeftIndex) => (
                      <Text mb="36px" key={`paragraph-${paragraphLeftIndex}`}>
                        {paragraphLeft}
                      </Text>
                    ),
                  )}
                  {contentLeft.bold && (
                    <Text
                      fontWeight="700"
                      fontStyle="italic"
                      mt={['55px', '70px']}
                    >
                      {contentLeft.bold}
                    </Text>
                  )}
                  {contentLeft.color && (
                    <Text fontWeight="500" color="#2b7a77">
                      {contentLeft.color}
                    </Text>
                  )}
                  {contentLeft.link && (
                    <Text>
                      {contentLeft.link.textBefore}{' '}
                      <LinkStyled to={contentLeft.link.url}>
                        {contentLeft.link.text}
                      </LinkStyled>
                      .
                    </Text>
                  )}
                </React.Fragment>
              ))}
            </ContentLeft>
            <ContentRight first={item.first}>
              {item.contentsRight?.map((contentRight, contentRightIndex) => (
                <React.Fragment key={`content-right-${contentRightIndex}`}>
                  {contentRight.image &&
                    (!isMobile || (isMobile && !item.first)) && (
                      <Image
                        src={temp[contentRight.image]}
                        alt={contentRight.image}
                      />
                    )}
                  {contentRight.paragraphs?.map(
                    (paragraphRight, paragraphRightIndex) => (
                      <Text
                        mb="20px"
                        key={`paragraph-right-${paragraphRightIndex}`}
                      >
                        {paragraphRight}
                      </Text>
                    ),
                  )}
                </React.Fragment>
              ))}
            </ContentRight>
          </Container>
        </Section>
      ))}

      <PageSection>
        <EmphContainer>
          <h3>Find a specific supplier</h3>
          <Button
            as={Link}
            to={routes.search}
            variant="outline"
            minW="248px"
            minH="56px"
            fontSize="18px"
            lineHeight="1.23em"
            backgroundColor="#fff"
          >
            Find a Supplier
          </Button>
        </EmphContainer>
      </PageSection>
    </>
  );
};

export default AboutUs;
