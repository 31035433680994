import React from 'react';
import PropTypes from 'prop-types';
import { Text, Circle, List, ListItem, Flex } from '@chakra-ui/react';

import { Banner, Content } from '@components/ProcurementValues/style';

const ValueBanner = ({ label, image, subsections }) => (
  <Banner
    image={image}
    className="banner"
    filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
  >
    <Content>
      <Text
        fontWeight={700}
        color="#2b7a77"
        fontSize="28px"
        mb="32px"
        fontFamily="'Nunito', sans-serif"
      >
        {label}
      </Text>
      <List>
        {subsections.map(x => (
          <ListItem key={x.id} mb="18px">
            <Flex sx={{ gap: '12px' }}>
              <Circle size="10px" bg="#2B7A77" mt="10px" />
              <Text
                className="content"
                fontWeight={400}
                lineHeight="32px"
                fontSize="24px"
                dangerouslySetInnerHTML={{ __html: x.content }}
              />
            </Flex>
          </ListItem>
        ))}
      </List>
    </Content>
  </Banner>
);

ValueBanner.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subsections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ValueBanner;
