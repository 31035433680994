import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Container, Box } from '@chakra-ui/react';
import DRAFT from '@assets/images/banners/draft.png';
import Banner, {
  BannerSubtitle,
  BannerInfo,
  BannerLinkWrapper,
} from '@components/Banner';
import SignUpSupplierForm from '@containers/SignUpSupplier/components/SignUpSupplierForm';
import routes from '@routes';

const SignUpSupplier = ({ profileStore, authStore }) => (
  <>
    <Banner title="I’m a Supplier" image={DRAFT} size="medium">
      <BannerSubtitle>
        Please answer as many questions as you can about your company.
      </BannerSubtitle>
      <BannerInfo>
        The more answers you can provide, reflecting your core services, the
        more relevant the Buyer matches will be for you.
        <BannerLinkWrapper>
          <Link to={routes.contactUs}>Contact us</Link>
        </BannerLinkWrapper>
      </BannerInfo>
    </Banner>
    <Container>
      <Box my={5}>
        <SignUpSupplierForm {...{ profileStore, authStore }} />
      </Box>
    </Container>
  </>
);

SignUpSupplier.propTypes = {
  profileStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default inject('profileStore', 'authStore')(observer(SignUpSupplier));
