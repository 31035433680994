import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import RadioGroup from '@components/Form/RadioGroup';
import CheckboxGroup from '@components/Form/CheckboxGroup';
import Checkbox from '@components/Form/Checkbox';
import { Flex, Box, Button, Center, Spinner } from '@chakra-ui/react';
import { Element } from 'react-scroll';
import { cloneDeep } from 'lodash-es';
import {
  PROJECT_SIZE_OPTIONS,
  PEOPLE_OPTIONS,
  INSTALLATION_OPTIONS,
  PREFERRED_MANUFACTURER_OPTIONS,
  PROJECT_CATEGORY_OPTIONS,
  LOCATION_TYPES_OPTIONS,
} from '@containers/SignUpBuyer/data';
import TextField from '@components/Form/TextField';
import { FullBgBox } from '@styles/globalStyles';
import { Title } from '@components/Form/style';
import Select from '@components/Form/Select';
import StepIndicator from '@components/Form/StepIndicator';
import toSnakeCase from '@utils/toSnakeCase';

const SignUpBuyerForm = ({
  authStore: {
    isAuthenticated,
    user: { buyer },
    guest: { buyer: buyerGuest },
  },
  profileStore: {
    isLoading,
    isSubmitting,
    getFormConfig,
    formConfig,
    onSubmit,
  },
}) => {
  useEffect(() => {
    getFormConfig();
  }, []);

  const {
    register,
    control,
    unregister,
    handleSubmit,
    watch,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (typeof buyer?.sectorIds !== 'string' && buyer?.sectorIds.length > 0) {
      buyer.sectorIds = JSON.stringify(
        buyer.sectorIds[Object.keys(buyer.sectorIds)[0]],
      );
    }
    if (buyer) {
      buyer.newFurniture = buyer.newFurniture ? 1 : 0;
      buyer.secondHand = buyer.secondHand ? 1 : 0;
      buyer.marketingContest = buyer.marketingContest ? 1 : 0;
    }
  }, [buyer]);

  useEffect(() => {
    reset(buyer ? cloneDeep(toSnakeCase(buyer)) : cloneDeep(buyerGuest));
  }, [formConfig, buyer, buyerGuest, isAuthenticated]);

  const inputProps = {
    register,
    errors,
    unregister,
  };

  const radioProps = {
    ...inputProps,
  };

  const textAreaProps = {
    as: 'textarea',
    maxW: '591px',
    ...inputProps,
  };

  const locationIds = watch('location_ids') || [];
  const locationTypes = watch('location_type_ids') || [];

  useEffect(() => {
    if (locationIds && locationIds.length > 0) {
      if (!locationTypes.includes('1')) {
        setValue('location_type_ids', [...locationTypes, '1']);
      }
    }
  }, [locationIds, locationTypes]);

  const isPreferredManufacturer = watch('preferred_manufacturer') === 'true';

  return (
    <Box mt="80px">
      <StepIndicator numSteps={2} currentStep={0} />

      <Box
        as="form"
        zIndex={-1}
        mr="42px"
        onSubmit={handleSubmit(data => onSubmit('buyer', data, setError))}
      >
        {isLoading && (
          <Center
            {...{
              position: 'fixed',
              background: 'rgba(255,255,255,0.5)',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Spinner />
          </Center>
        )}
        <Element name="step-1">
          <RadioGroup
            {...{
              title: 'Which sector best reflects your project?',
              name: 'sector_ids',
              options: formConfig?.sectors,
              ...radioProps,
            }}
          />
          <RadioGroup
            {...{
              title: 'What is the budget for this project?',
              name: 'project_size',
              options: PROJECT_SIZE_OPTIONS,
              ...radioProps,
            }}
          />
          <RadioGroup
            {...{
              title: 'Number of people:',
              name: 'people',
              options: PEOPLE_OPTIONS,
              ...radioProps,
            }}
          />
          <TextField
            {...{
              title: 'Please enter your contact number:',
              name: 'phone',
              label: 'Phone',
              placeholder: 'Type your contact number',
              maxW: '340px',
              ...inputProps,
            }}
          />
          <TextField
            {...{
              title:
                'Please enter the postcode for the main location of the project:',
              name: 'postcode',
              label: 'Postcode',
              placeholder: 'Type your Postcode',
              maxW: '340px',
              required: true,
              ...inputProps,
            }}
          />

          <CheckboxGroup
            {...{
              name: 'location_type_ids',
              title:
                'If the project is in multiple locations please tick the relevant boxes:',
              options: LOCATION_TYPES_OPTIONS,
              ...inputProps,
            }}
          />
          {locationTypes.includes('1') && (
            <CheckboxGroup
              {...{
                name: 'location_ids',
                title: 'Please specify other project locations:',
                isGird: true,
                options: formConfig.locations,
                ...inputProps,
              }}
            />
          )}
        </Element>
        <Element name="step-2">
          <FullBgBox pt="80px" pb="20px" mb="80px">
            <RadioGroup
              {...{
                title:
                  'Please categorise the project as follows High quality, Medium Price and quality, Low Price Point:',
                name: 'project_categorise',
                options: PROJECT_CATEGORY_OPTIONS,
                ...radioProps,
              }}
            />
            <RadioGroup
              {...{
                title: 'Do you have a preferred manufacturer?',
                name: 'preferred_manufacturer',
                options: PREFERRED_MANUFACTURER_OPTIONS,
                ...radioProps,
                ...(isPreferredManufacturer && { sx: { marginBottom: 4 } }),
              }}
            />
            {isPreferredManufacturer && (
              <Select
                name="manufacturer_ids"
                placeholder="Select manufacturers..."
                isMulti
                control={control}
                options={formConfig.manufacturers}
                max={3}
              />
            )}
            <RadioGroup
              {...{
                title: 'When do you need your furniture installed by?',
                name: 'install_time',
                options: INSTALLATION_OPTIONS,
                ...radioProps,
              }}
            />
            <CheckboxGroup
              {...{
                title:
                  'What services are you likely to need from your supplier?',
                name: 'service_ids',
                isGird: true,
                options: formConfig.services,
                ...inputProps,
              }}
            />
            <Box position="relative" mb="60px">
              <Title>Please tick which furniture you are interested in:</Title>
              <Flex>
                <div>
                  <Checkbox
                    {...{
                      name: 'new_furniture',
                      label: 'New furniture',
                      register,
                      errors,
                      ofGroup: true,
                    }}
                  />
                </div>
                <div>
                  <Checkbox
                    {...{
                      name: 'second_hand',
                      label: 'Second hand furniture',
                      register,
                      errors,
                      ofGroup: true,
                    }}
                  />
                </div>
              </Flex>
            </Box>
            <CheckboxGroup
              {...{
                title:
                  'Do you require any of the following accreditations for the company to be eligible to be a supplier?',
                name: 'iso_norm_ids',
                options: formConfig.isoNorms,
                ...inputProps,
              }}
            />

            {/* Logged in user content only */}
            {isAuthenticated && (
              <Box>
                <TextField
                  {...{
                    title:
                      'Please provide any additional Comments which will help suppliers understand your requirement:',
                    name: 'comment',
                    label: 'Comment',
                    ...textAreaProps,
                  }}
                />

                <Checkbox
                  {...{
                    name: 'marketing_contest',
                    label: 'I want to receive further info',
                    register,
                    errors,
                  }}
                />
              </Box>
            )}
          </FullBgBox>
        </Element>
        <Box mt={5}>
          <Button
            type="submit"
            size="lg"
            w="340px"
            maxW="100%"
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SignUpBuyerForm.propTypes = {
  profileStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default observer(SignUpBuyerForm);
