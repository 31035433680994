import React from 'react';
import Banner from '@components/Banner';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Link as ChakraLink,
  Box,
  Button,
  Flex,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import thankYou from '@assets/images/banners/thankYou.png';
import { Link } from 'react-router-dom';
import routes from '@routes';
import { PageSection } from '@styles/globalStyles';
import { ContentRight } from '@containers/AboutUs/style';

// eslint-disable-next-line react/prop-types
const StyledItem = ({ children, ...rest }) => (
  <Flex
    fontSize={18}
    lineHeight="1.3"
    flexDir={{
      base: 'column',
      md: 'row',
    }}
    alignItems={{
      base: 'center',
      md: 'flex-start',
    }}
    {...rest}
  >
    <Box
      as={ArrowForwardIcon}
      color="aqua.500"
      fontSize={22}
      mt="3px"
      mr={4}
      mb={{
        base: 4,
        md: 0,
      }}
      transform={{
        base: 'rotate(90deg)',
        md: 'none',
      }}
    />
    {children}
  </Flex>
);

const ThankYou = () => (
  <>
    <Banner title="Thank you!" />
    <PageSection textAlign={{ base: 'center', md: 'initial' }}>
      <Flex>
        <Box
          w={{
            base: '100%',
            md: '50%',
          }}
          pt="52px"
          pr={{
            base: 0,
            md: '36px',
          }}
        >
          <Text
            fontFamily="'Nunito', sans-serif"
            fontSize={28}
            lineHeight="34px"
            mb="32px"
          >
            {'To ensure you gain the most benefit from '}
            <Text mr="8px" as="span" fontWeight="700">
              BuyerSupplier.co.uk
            </Text>
            we recommend:
          </Text>
          <VStack spacing={4}>
            <StyledItem>
              that you complete as much of the supplier questionnaire as
              possible. This will improve your visibility in searches and ensure
              you have a higher match with potential Buyers.
            </StyledItem>
            <StyledItem>
              that you take out a monthly / annual subscription which means that
              your contact details are provided to potential buyers and it is
              therefore easier for Buyers to contact you if you appear in
              searches. (Note free accounts provide the name of your company and
              your search score only)
            </StyledItem>
          </VStack>
          <Button
            as={Link}
            to={routes.auth.signIn}
            h="56px"
            ml="36px"
            mt="28px"
            justifyContent="center"
            w="100%"
            maxW="248px"
            fontSize={18}
            rightIcon={<ArrowForwardIcon fontSize={24} />}
            display={{
              base: 'none',
              md: 'flex',
            }}
          >
            Log in
          </Button>
        </Box>
        <ContentRight
          first
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          <Image w="100%" src={thankYou} borderRadius="60px 4px 4px 4px" />
        </ContentRight>
      </Flex>

      <Box
        bg="aqua.50"
        px={{
          base: '26px',
          md: '75px',
        }}
        py={{
          base: '50px',
          md: '75px',
        }}
        mb="90px"
        mt={{
          base: '60px',
          md: 0,
        }}
        rounded={{
          base: 'md',
          md: 'none',
        }}
      >
        <Box mb="25px">
          <Text
            mr="8px"
            fontSize={{
              base: 20,
              md: 26,
            }}
            lineHeight="1.3"
            fontFamily="'Nunito', sans-serif"
          >
            Please contact us if you have received this email but have not added
            or updated your details or if you have any other questions. You can
            do this by emailing us on
            <Text
              mr="8px"
              fontWeight="700"
              fontFamily="'Nunito', sans-serif"
              color="aqua.400"
              textDecoration="underline"
              href="mailto:support@buyersupplier.co.uk"
              as={ChakraLink}
              ml="8px"
              _hover={{ color: 'aqua.700' }}
            >
              support@buyersupplier.co.uk
            </Text>
          </Text>
        </Box>
        <Text
          fontSize={20}
          lineHeight="30px"
          fontWeight="600"
          fontFamily="'Nunito', sans-serif"
        >
          Many Thanks,
        </Text>
        <Text
          fontSize={20}
          lineHeight="30px"
          fontStyle="italic"
          fontWeight="400"
          fontFamily="'Nunito', sans-serif"
        >
          - Buyer/Supplier Team.
        </Text>
      </Box>
    </PageSection>
  </>
);

export default ThankYou;
