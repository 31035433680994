import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import routes from '@routes';
import FormError from '@components/Form/FormMessage';
import TextField from '@components/Form/TextField';
import Checkbox from '@components/Form/Checkbox';
import RadioGroup from '@components/Form/RadioGroup';

const SignUpForm = ({
  onSubmit,
  isLoading,
  uuid,
  base64,
  isPotentialSupplier,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const inputProps = {
    register,
    errors,
    required: true,
    sx: {
      marginBottom: '40px',
    },
  };

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data, setError, uuid))}>
      <Flex width="full" alignItems="center" flexDirection="column">
        <FormError message={errors.global?.message} />
        <TextField label="E-mail" name="email" {...inputProps} />
        <Box
          width="full"
          mb={3}
          sx={{
            position: 'relative',
          }}
        >
          <TextField
            label="Password"
            type="password"
            name="password"
            autoComplete="new-password"
            {...inputProps}
          />
          <TextField
            label="Confirm password"
            type="password"
            name="password_confirmation"
            autoComplete="new-password"
            {...inputProps}
          />
          {!uuid && !isPotentialSupplier && !base64 && (
            <RadioGroup
              title="Account type"
              name="user_type"
              options={[
                { label: 'Buyer', value: 'buyer' },
                { label: 'Supplier', value: 'supplier' },
              ]}
              register={register}
              sx={{
                mb: '40px',
              }}
            />
          )}
          {isPotentialSupplier && (
            <TextField
              label="Client Type"
              type="hidden"
              name="client_type"
              value="supplier"
              readOnly
              register={register}
            />
          )}
          {base64 && (
            <TextField
              label="base64"
              type="hidden"
              name="base64"
              value={base64}
              readOnly
              register={register}
            />
          )}
          <Checkbox
            {...{
              name: 'terms_and_conditions',
              label: 'I accept the',
              afterLabel: (
                <Link
                  to={routes.termsAndConditions}
                  style={{
                    color: '#2b7a77',
                  }}
                >
                  Terms and Conditions
                </Link>
              ),
              register,
              required: true,
              errors,
            }}
          />
        </Box>
        <Button type="submit" w="full" minH="56px" isLoading={isLoading}>
          Sign up
        </Button>
      </Flex>
    </form>
  );
};

SignUpForm.defaultProps = {
  isLoading: false,
  uuid: null,
  base64: null,
  isPotentialSupplier: false,
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  uuid: PropTypes.string,
  base64: PropTypes.string,
  isPotentialSupplier: PropTypes.bool,
};

export default SignUpForm;
