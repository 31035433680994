import styled from '@emotion/styled/macro';
import isPropValid from '@emotion/is-prop-valid';
import { Box, IconButton } from '@chakra-ui/react';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled(Box)`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ShadowLayer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const ContentWrapper = styled(Box, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 100%;
  min-height: 250px;
  background-color: ${({ theme }) => theme.backgrounds.pageContent};
  border-radius: 4px;
  padding: 30px;
  ${({ version }) =>
    version === 'wide' &&
    `
    width: 80%;
    padding: 0;
    background-color: transparent;
    @media ${breakpoint.md} {
      width: 100%;
      max-height: 100%;
      overflow: auto;
    }
  `};
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  img {
    width: 20px;
    height: 20px;
  }
`;
