import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Box, Container } from '@chakra-ui/react';
import BANNER from '@assets/images/banners/office2.png';
import Banner, {
  BannerSubtitle,
  BannerInfo,
  BannerLinkWrapper,
} from '@components/Banner';
import SignUpBuyerForm from '@containers/SignUpBuyer/components/SignUpBuyerForm';
import routes from '@routes';
import { NoOverflowWrapper } from '@styles/globalStyles';

const SignUpBuyer = ({ profileStore, authStore }) => (
  <NoOverflowWrapper>
    <Banner title="I’m a Buyer" image={BANNER} size="medium">
      <BannerSubtitle>
        Please answer as many questions as you can about your project.
      </BannerSubtitle>
      <BannerInfo>
        The more answers you can provide the more relevant the Supplier matches
        will be for you.
        <BannerLinkWrapper>
          <Link to={routes.contactUs}>Contact us</Link>
        </BannerLinkWrapper>
      </BannerInfo>
    </Banner>
    <Container>
      <Box my={5}>
        <SignUpBuyerForm {...{ profileStore, authStore }} />
      </Box>
    </Container>
  </NoOverflowWrapper>
);

SignUpBuyer.propTypes = {
  profileStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default inject('profileStore', 'authStore')(observer(SignUpBuyer));
