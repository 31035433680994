import { useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { breakpoint, BREAKPOINTS } from '@styles/breakpoints';

function useBreakpoint(delay = 300) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const debouncedHandleResize = debounce(handleResize, delay);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  const matchBreakpoint = bp => window.matchMedia(breakpoint[bp]).matches;

  return { matchBreakpoint, width, BREAKPOINTS };
}

export default useBreakpoint;
