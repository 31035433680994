import axios from 'axios';
import { authStore } from '@stores';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      authStore.logout();
    }
    return Promise.reject(error.response.data);
  },
);

const setAuthenticationToken = authHeaders => {
  localStorage.setItem('auth-headers', JSON.stringify(authHeaders));

  API.defaults.headers.common = authHeaders;
};

const clearAuthenticationToken = () => {
  localStorage.removeItem('auth-headers');

  API.defaults.headers.common = {};
};

const pathVersion = {
  basic: 'v1',
};

const APIRoutes = {
  SIGN_UP: `/${pathVersion.basic}/auth`,
  SIGN_IN: `/${pathVersion.basic}/auth/sign_in`,
  RESET_PASSWORD: `/${pathVersion.basic}/auth/password`,
  RESET_PASSWORD_CONFIRMATION: `/${pathVersion.basic}/auth/password`,
  PROFILE: `/${pathVersion.basic}/users/profile`,
  SUPPLIERS_HP_CAROUSEL: `/${pathVersion.basic}/suppliers?per=12`,
  FORM_CONFIG: `/${pathVersion.basic}/config/signup_form`,
  SAVED_SEARCHES: `/${pathVersion.basic}/searches`,
  SEARCH: (query = '', uuid, page = 1, per = 24) =>
    `/${pathVersion.basic}/search?page=${page}&per=${per}${
      uuid ? `&uuid=${uuid}` : ''
    }${query && `&q=${query}`}`,
  CREATE: {
    buyer: `/${pathVersion.basic}/buyers`,
    supplier: `/${pathVersion.basic}/suppliers`,
  },
  EDIT: {
    buyer: id => `/${pathVersion.basic}/buyers/${id}`,
    supplier: id => `/${pathVersion.basic}/suppliers/${id}`,
  },
  STATIC_FAQ: `/${pathVersion.basic}/static_pages/faq`,
  STATIC_TC: `/${pathVersion.basic}/static_pages/terms_and_conditions`,
  STATIC_PP: `/${pathVersion.basic}/static_pages/privacy_policy`,
  LOGOUT: `/${pathVersion.basic}/auth/sign_out`,
  CONTACT_US: `/${pathVersion.basic}/contact_us`,
  CONNECT_BUYER_AND_SUPPLIER: supplierSlug =>
    `/${pathVersion.basic}/suppliers/${supplierSlug}/contact`,
};

export { API, APIRoutes, setAuthenticationToken, clearAuthenticationToken };
