import styled from '@emotion/styled/macro';
import isPropValid from '@emotion/is-prop-valid';
import { Box, Flex, Text } from '@chakra-ui/react';
import BANNERARROWS from '@assets/images/shapes/main-arrows.svg';
import { breakpoint } from '@styles/breakpoints';

export const Container = styled(Flex, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  width: 100%;
  min-height: ${({ theme, bSize, newBanner }) =>
    newBanner ? 'unset' : theme.banner.height[bSize].desktop};
  background-color: ${({ theme }) => theme.backgrounds.primary};
  @media ${breakpoint.md} {
    min-height: ${({ theme, bSize, newBanner }) =>
      newBanner ? 'unset' : theme.banner.height[bSize].mobile};
  }
`;

export const TextContainer = styled(Box, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  background-image: url(${BANNERARROWS});
  background-repeat: no-repeat;
  ${({ bSize }) =>
    bSize === 'small' &&
    `
    background-size: 1486px;
    background-position: left -620px center;
    @media ${breakpoint.xxl} {
      background-position: left -780px center;
    }
    @media ${breakpoint.xl} {
      background-position: left -860px center;
    }
    @media ${breakpoint.md} {
      background-size: 174%;
      background-position: right -3% center;
    }
  `};
  ${({ bSize }) =>
    bSize === 'medium' &&
    `
    background-size: 72%;
    background-position: left -300px center;
    @media ${breakpoint.xxl} {
      background-position: left -365px center;
    }
    @media ${breakpoint.xl} {
      background-position: left -330px center;
    }
    @media ${breakpoint.lg} {
      background-position: left -276px center;
    }
    @media ${breakpoint.md} {
      background-size: 172%;
      background-position: right -10% center;
    }
    @media ${breakpoint.xs} {
      background-size: 200%;
    }
  `};
  ${({ bSize }) =>
    bSize === 'large' &&
    `
    background-size: 1486px;
    background-position: right 89px top 12%;
    @media ${breakpoint.md} {
      background-size: 216%;
      background-position: right -8% center;
    }
  `};
  @media ${breakpoint.md} {
    width: 100%;
  }
`;

const TextContainerCalcWidth = ({ theme, fullWidth }) =>
  `(${theme.page.pageWidth} + 2 * ${theme.page.sidePadding}) / ${
    fullWidth ? '1' : '2'
  }`;

export const TextContainerInner = styled(Flex, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  ${({ fullWidth }) => !fullWidth && 'margin-right: 0'};
  padding: 20px ${({ theme }) => theme.page.sidePadding};
  ${({ fullWidth }) => !fullWidth && 'padding-right: 30px'};
  ${({ bSize }) => bSize === 'medium' && 'padding-right: 150px'};
  max-width: calc(${TextContainerCalcWidth});
  color: ${({ theme }) => theme.colors.secondary};
  @media ${breakpoint.xl} {
    ${({ bSize }) => bSize === 'medium' && 'padding-right: 20px'};
  }
  @media ${breakpoint.md} {
    margin-right: auto;
    text-align: center;
    ${({ bSize }) => bSize === 'medium' && 'padding-right: 40px'};
    ${({ bSize }) => bSize === 'medium' && 'padding-left: 40px'};
  }
  @media ${breakpoint.xs} {
    ${({ bSize }) => bSize === 'medium' && 'padding-right: 16px'};
    ${({ bSize }) => bSize === 'medium' && 'padding-left: 16px'};
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  ${({ bSize }) =>
    bSize === 'large' &&
    `
    margin: 53px 0;
    border-radius: 60px 0 0 4px;
  `};
`;

export const BannerSubtitle = styled(Text)`
  margin-top: 20px;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  @media ${breakpoint.md} {
    margin-top: 12px;
  }
`;

export const BannerInfo = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  @media ${breakpoint.md} {
    margin-top: 14px;
  }
  a {
    color: ${({ theme }) => theme.colors.emphLight};
    text-decoration: underline;
    transition: ${({ theme }) => theme.transitionColor};
    &:hover {
      color: ${({ theme }) => theme.colors.hover};
    }
  }
`;

export const BannerLinkWrapper = styled.div`
  margin-top: 17px;
  font-size: 21px;
  @media ${breakpoint.md} {
    margin-top: 21px;
  }
`;

const PageSectionCalcWidth = ({ theme }) =>
  `${theme.page.pageWidth} + 2 * ${theme.page.sidePadding}`;

export const NewBannerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 45px 0 45px 20px;
  max-width: calc(${PageSectionCalcWidth});

  margin: 0 auto;

  @media ${breakpoint.lg} {
    padding: 32px 20px;
    padding-bottom: 320px;
  }

  @media ${breakpoint.md} {
    padding-bottom: 190px;

    a {
      width: 100%;
    }
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @media ${breakpoint.lg} {
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  align-self: flex-end;

  @media ${breakpoint.lg} {
    align-self: flex-start;
  }
`;

export const NewBannerTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 64px;
  line-height: 83px;
  max-width: 600px;

  span {
    font-weight: 700;
  }

  @media (max-width: 1440px) {
    font-size: 56px;
    line-height: 70px;
  }

  @media ${breakpoint.xl} {
    font-size: 48px;
    line-height: 60px;
  }

  @media ${breakpoint.lg} {
    font-size: 42px;
    line-height: 50px;
    max-width: 400px;
  }

  @media ${breakpoint.md} {
  }
`;

export const ImageWrapper = styled.div`
  @media ${breakpoint.lg} {
    display: none;
  }
`;

export const NewBannerSubtitle = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  padding-top: 32px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  max-width: 600px;

  @media (max-width: 1440px) {
    font-size: 28px;
    line-height: 34px;
    max-width: 450px;
  }

  @media ${breakpoint.xl} {
    font-size: 24px;
    line-height: 30px;
    max-width: 400px;
  }

  @media ${breakpoint.lg} {
    font-size: 22px;
    max-width: unset;
  }

  @media ${breakpoint.md} {
  }
`;

export const Hug = styled.div`
  position: relative;

  @media ${breakpoint.lg} {
    padding-bottom: 250px;
  }

  @media ${breakpoint.md} {
    padding-bottom: 170px;
  }
`;

export const MobileImageWrapper = styled.div`
  position: absolute;
  bottom: -10px;
  width: 100%;
  padding: 0 20px;
`;

export const MobileImage = styled.div`
  background-image: ${({ image }) => `url(${image})`};

  width: 100%;
  height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: none;

  @media ${breakpoint.lg} {
    display: block;
  }

  @media ${breakpoint.md} {
    height: 343px;
  }
`;
