import { makeAutoObservable, runInAction } from 'mobx';
import { APIRoutes, API } from '@app/api';
// import { uniqBy } from 'lodash-es';
import profileStore from './profileStore';

export class SuppliersStore {
  constructor() {
    makeAutoObservable(this);
  }

  hpCarouselSuppliers = {
    isLoading: false,
    data: [],
    error: null,
  };

  searchResults = {
    isLoading: false,
    currentQuery: '',
    data: [],
    meta: {},
    error: null,
  };

  getHpCarouselSuppliers = async () => {
    this.hpCarouselSuppliers.isLoading = true;

    try {
      const {
        data: { suppliers },
      } = await API.get(APIRoutes.SUPPLIERS_HP_CAROUSEL);
      runInAction(() => {
        this.hpCarouselSuppliers.data = suppliers;
      });
    } catch (error) {
      runInAction(() => {
        this.hpCarouselSuppliers.error = error;
      });
    } finally {
      runInAction(() => {
        this.hpCarouselSuppliers.isLoading = false;
      });
    }
  };

  getSearchResults = async (query = '', page = 1) => {
    if (!this.searchResults.isLoading) {
      runInAction(() => {
        if (page === 1) {
          this.clearSearchResults();
        }
      });
      this.searchResults.isLoading = true;

      try {
        const data = await API.get(
          APIRoutes.SEARCH(query, profileStore.uuid, page),
        );
        runInAction(() => {
          this.searchResults.currentQuery = query;
          this.searchResults.data.push(...data.data);
          // this.searchResults.data = uniqBy(this.searchResults.data, 'slug');
        });
      } catch (error) {
        runInAction(() => {
          this.searchResults.error = error;
        });
      } finally {
        runInAction(() => {
          this.searchResults.isLoading = false;
        });
      }
    }
  };

  clearStore = () => {
    this.hpCarouselSuppliers = {
      isLoading: false,
      data: [],
      error: null,
    };
    this.searchResults = {
      isLoading: false,
      currentQuery: '',
      data: [],
      meta: {},
      error: null,
    };
  };

  clearSearchResults = () => {
    this.searchResults = {
      isLoading: false,
      data: [],
      error: null,
    };
  };
}

export default new SuppliersStore();
