import styled from '@emotion/styled/macro';
import { breakpoint } from '@styles/breakpoints';
import { Box, Button } from '@chakra-ui/react';

export const InfoBoxWrapper = styled(Box)`
  position: relative;
  padding: 53px 56px;
  border: 1px solid ${({ theme }) => theme.borders.secondary};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  @media ${breakpoint.lg} {
    text-align: center;
  }
  @media ${breakpoint.md} {
    padding: 37px 30px 136px;
  }
  @media ${breakpoint.sm} {
    padding: 20px 15px 136px;
    font-size: 15px;
    overflow: hidden;
    a {
      font-size: 15px;
    }
  }
`;

export const LogoWrapper = styled(Box)`
  height: 56px;
  img {
    height: 100%;
  }
`;

export const Label = styled.span`
  margin-right: 5px;
  font-weight: 500;
`;

export const SettingsButton = styled(Button)`
  @media ${breakpoint.md} {
    position: absolute;
    padding: 0;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const PaymentButton = styled(Button)`
  @media ${breakpoint.md} {
    position: absolute;
    padding: 0;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
