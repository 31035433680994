import OFFICE from '@assets/images/banners/office3.png';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import Banner from '@components/Banner';
import { Container } from '@containers/AboutUs/style';
import { ContentLeft, ContentRight } from '@containers/ContactUs/style';
import ResetPasswordConfirmationForm from '@containers/ResetPasswordConfirmation/components/ResetPasswordConfirmationForm';
import useBreakpoint from '@hooks/useBreakpoint';
import routes from '@routes';
import { MediumHeading } from '@styles/globalStyles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const ResetPasswordConfirmationPage = ({
  authStore: { resetPasswordConfirmation, isLoading },
}) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const { token } = useParams();

  return (
    <>
      <Banner title="Reset password" />
      <Container>
        <Flex flexWrap="wrap">
          <ContentLeft>
            <MediumHeading mb="34px">Enter new password</MediumHeading>
            <Box
              width={{
                base: 'full',
                lg: '95%',
                xl: '85%',
              }}
            >
              <ResetPasswordConfirmationForm
                onSubmit={resetPasswordConfirmation}
                isLoading={isLoading}
                token={token}
              />
              <Box mt="34px" pt="34px" borderTop="1px solid #adbec0">
                <Button
                  as={Link}
                  to={routes.signUp}
                  w="full"
                  minH="56px"
                  variant="outline"
                >
                  Register
                </Button>
              </Box>
            </Box>
          </ContentLeft>

          {!isMobile && (
            <ContentRight>
              <Image src={OFFICE} alt="" />
            </ContentRight>
          )}
        </Flex>
      </Container>
    </>
  );
};

ResetPasswordConfirmationPage.propTypes = {
  authStore: PropTypes.object.isRequired,
};

export default inject('authStore')(observer(ResetPasswordConfirmationPage));
