import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, ListItem } from '@chakra-ui/react';
import BANNER from '@assets/images/banners/office.png';
import Banner from '@components/Banner';
import InfoBoxCarousel from '@components/InfoBoxCarousel';
import {
  BannerInfo,
  BannerSubtitle,
  BoxHeading,
  Carousel,
  CarouselHeader,
  CarouselWrapper,
  ListItemStyled,
  OrderedListStyled,
  ThreeColumnsContainer,
} from '@containers/Home/style';
import useBreakpoint from '@hooks/useBreakpoint';
import routes from '@routes';

const FindAFurnitureDealerShip = () => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  return (
    <>
      <Banner
        title="Let BuyerSupplier find the best furniture supplier for you"
        image={BANNER}
        size="large"
      >
        <BannerSubtitle>
          The UK’s first comparison site for office furniture dealerships
        </BannerSubtitle>
        <BannerInfo>
          See our <Link to={routes.faq}>FAQ</Link> for further information
          <br />
          or <Link to={routes.contactUs}>contact us</Link> for more questions.
        </BannerInfo>
      </Banner>

      <ThreeColumnsContainer>
        <Box flex="1">
          <BoxHeading>We at BuyerSupplier:</BoxHeading>
          <OrderedListStyled spacing="30px" mt="30px">
            <ListItemStyled as={ListItem}>
              Compare the UK’s best office furniture suppliers.
            </ListItemStyled>
            <ListItemStyled as={ListItem}>
              Have extensive knowledge of the UK furniture industry.
            </ListItemStyled>
            <ListItemStyled as={ListItem}>
              Make it a simple easy process to find the right supplier for you.
            </ListItemStyled>
          </OrderedListStyled>
        </Box>

        <Box flex="1">
          <BoxHeading>BuyerSupplier, how it works:</BoxHeading>
          <OrderedListStyled spacing="30px" mt="30px">
            <ListItemStyled as={ListItem}>
              You fill in an easy questionnaire to find your tailored partner.
            </ListItemStyled>
            <ListItemStyled as={ListItem}>
              We find the right suppliers based on your answers.
            </ListItemStyled>
            <ListItemStyled as={ListItem}>
              You find long term relationships for your furniture requirements.
            </ListItemStyled>
          </OrderedListStyled>
        </Box>

        <Box flex="1">
          <BoxHeading>BuyerSupplier can help:</BoxHeading>
          <OrderedListStyled spacing="30px" mt="30px">
            <ListItemStyled as={ListItem}>
              Take stress away from finding office furniture.
            </ListItemStyled>
            <ListItemStyled as={ListItem}>
              Save time and let somebody else do the hard work.
            </ListItemStyled>
            <ListItemStyled as={ListItem}>
              Find suppliers that can build your brand and culture in your
              workplace.
            </ListItemStyled>
          </OrderedListStyled>
        </Box>
      </ThreeColumnsContainer>

      <Box textAlign="center" mb={['70px', '90px']}>
        <Button
          as={Link}
          to={routes.search}
          flex={1}
          variant="outline"
          minW="248px"
          minH="60px"
          fontSize={['24px', '27px']}
        >
          Find a Supplier
        </Button>
      </Box>

      <Box
        textAlign="center"
        mb={['70px', '90px']}
        mx="auto"
        width={['320px', '460px', '740px', '960px', '1024px']}
        height={['180px', '258px', '416px', '540px', '576px']}
      >
        <iframe
          src="https://www.youtube.com/embed/XFmBmR2BS5M?origin=https://www.buyersupplier.co.uk&modestbranding=1&rel=0"
          wmode="transparent"
          type="video/mp4"
          width="100%"
          height="100%"
          allow="autoplay; encrypted-media; picture-in-picture"
          allowFullScreen
          title="BuyerSupplier.co.uk introduction"
        />
      </Box>

      {!isMobile && (
        <CarouselWrapper>
          <Carousel>
            <CarouselHeader>
              <h3>Suppliers in your area</h3>
            </CarouselHeader>
            <InfoBoxCarousel />
          </Carousel>
        </CarouselWrapper>
      )}
    </>
  );
};

export default FindAFurnitureDealerShip;
