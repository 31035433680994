import React from 'react';
import { Ball, InnerBall, PreloaderWrapper } from '@components/Preloader/style';

const Preloader = () => (
  <PreloaderWrapper>
    <Ball id="Ball_1">
      <InnerBall />
    </Ball>
    <Ball id="Ball_2">
      <InnerBall />
    </Ball>
    <Ball id="Ball_3">
      <InnerBall />
    </Ball>
    <Ball id="Ball_4">
      <InnerBall />
    </Ball>
    <Ball id="Ball_5">
      <InnerBall />
    </Ball>
  </PreloaderWrapper>
);

export default Preloader;
