import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import isPropValid from '@emotion/is-prop-valid';
import { Box } from '@chakra-ui/react';
import { PageSection } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const Container = styled(PageSection)`
  justify-content: space-between;
  @media ${breakpoint.md} {
    flex-wrap: wrap;
    text-align: center;
  }
`;

export const EmphContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.backgrounds.secondary};
  align-items: center;
  padding: 58px 61px 57px 77px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  h3 {
    @media ${breakpoint.xl} {
      font-size: 32px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media ${breakpoint.xl} {
    flex-wrap: wrap;
    margin: 30px;
    padding: 67px 33px 70px;
    text-align: center;
    a {
      margin: 23px auto 0;
    }
  }
`;

export const Section = styled(Box, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  ${({ theme, even }) =>
    even && `background-color: ${theme.backgrounds.secondary}`};
`;

export const ContentLeft = styled(Box, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  padding: ${({ first }) => (first ? '70px' : '120px')} 56px 120px 0;
  width: 50%;
  ${({ first }) =>
    first
      ? `
      font-size: 26px;
      line-height: 1.2em;
      font-family: 'Nunito', sans-serif;
      @media ${breakpoint.md} {
        font-size: 21px;
      }
      `
      : `
      font-size: 18px;
      line-height: 1.5em;
      `};
  @media ${breakpoint.md} {
    width: 100%;
    padding: 66px 10px 0;
    p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const ContentRight = styled(Box, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  position: relative;
  padding: ${({ first }) => (first ? '70px 0 0' : '120px 50px 120px 0')};
  width: 50%;
  ${({ first }) =>
    first
      ? `
      font-size: 26px;
      line-height: 1.2em;
      `
      : `
      font-size: 18px;
      line-height: 1.5em;
      `};
  img {
    border-radius: ${({ first }) => (first ? '60px 4px 4px' : '4px')};
    box-shadow: ${({ theme }) => theme.shadows.primary};
    ${({ first }) =>
      first
        ? `
      position: relative;
      top: -140px;
      `
        : `
      margin-bottom: 40px;
      `};
  }
  @media ${breakpoint.md} {
    width: 100%;
    padding: 66px 10px 0;
  }
`;

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.colors.emph};
  &:hover {
    text-decoration: underline;
  }
`;
