import OFFICE from '@assets/images/banners/office3.png';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import Banner from '@components/Banner';
import { Container } from '@containers/AboutUs/style';
import { ContentLeft, ContentRight } from '@containers/ContactUs/style';
import useBreakpoint from '@hooks/useBreakpoint';
import routes from '@routes';
import { MediumHeading } from '@styles/globalStyles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ResetPasswordForm from './components/ResetPasswordForm';

const ResetPasswordPage = ({ authStore: { resetPassword, isLoading } }) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  return (
    <>
      <Banner title="Reset password" />
      <Container>
        <Flex flexWrap="wrap">
          <ContentLeft>
            <MediumHeading mb="34px">Send password reset email</MediumHeading>
            <Box
              width={{
                base: 'full',
                lg: '95%',
                xl: '85%',
              }}
            >
              <ResetPasswordForm
                onSubmit={resetPassword}
                isLoading={isLoading}
              />
              <Box mt="34px" pt="34px" borderTop="1px solid #adbec0">
                <Button
                  as={Link}
                  to={routes.signUp}
                  w="full"
                  minH="56px"
                  variant="outline"
                >
                  Register
                </Button>
              </Box>
            </Box>
          </ContentLeft>

          {!isMobile && (
            <ContentRight>
              <Image src={OFFICE} alt="" />
            </ContentRight>
          )}
        </Flex>
      </Container>
    </>
  );
};

ResetPasswordPage.propTypes = {
  authStore: PropTypes.object.isRequired,
};

export default inject('authStore')(observer(ResetPasswordPage));
