import abbvie from '@assets/images/logos/abbvie.svg';
import adurWorthing from '@assets/images/logos/adur-worthing.svg';
import barbadosIntegratedGovernment from '@assets/images/logos/barbados-integrated-government.png';
import britishAirways from '@assets/images/logos/British_Airways.svg';
import canopius from '@assets/images/logos/canopius.png';
import cisco from '@assets/images/logos/cisco.svg';
import DDCMS from '@assets/images/logos/Department_for_Digital_Culture_Media_Sport.svg';
import DWP from '@assets/images/logos/Department_for_Work_and_Pensions.svg';
import ECC from '@assets/images/logos/Essex_County_Council.svg';
import etonCollege from '@assets/images/logos/eton-college.svg';
import gowlingWLG from '@assets/images/logos/gowling-wlg.svg';
import KCL from '@assets/images/logos/kings-college-london.svg';
import lloydsRegister from '@assets/images/logos/lloyds-register.svg';
import mufg from '@assets/images/logos/mufg.svg';
import NMTW from '@assets/images/logos/nhs-maidstone-and-tunbridge-wells.png';
import sage from '@assets/images/logos/sage.svg';
import siemens from '@assets/images/logos/siemens.svg';
import TDC from '@assets/images/logos/Tendring_District_Council.svg';
import theNec from '@assets/images/logos/the_nec.co.uk.svg';
import UR from '@assets/images/logos/university-of-reading.svg';

export const companies = [
  { id: 1, logo: cisco },
  { id: 2, logo: barbadosIntegratedGovernment },
  { id: 3, logo: lloydsRegister },
  { id: 4, logo: DDCMS },
  { id: 5, logo: britishAirways },
  { id: 6, logo: abbvie },
  { id: 7, logo: adurWorthing },
  { id: 8, logo: canopius },
  { id: 9, logo: DWP },
  { id: 10, logo: ECC },
  { id: 11, logo: etonCollege },
  { id: 12, logo: gowlingWLG },
  { id: 13, logo: KCL },
  { id: 14, logo: mufg },
  { id: 15, logo: NMTW },
  { id: 16, logo: sage },
  { id: 17, logo: siemens },
  { id: 18, logo: TDC },
  { id: 19, logo: theNec },
  { id: 20, logo: UR },
];
