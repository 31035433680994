import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';

import routes from '@routes';

export const ROLES = {
  CUSTOMER: 'CUSTOMER',
  COMPOSER: 'COMPOSER',
};

const ProtectedRoute = ({
  authStore: { isAuthenticated, isAuthenticating },
  component: Component,
  ...rest
}) => {
  if (isAuthenticating) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.base} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    isAuthenticating: PropTypes.bool,
  }).isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default inject('authStore')(observer(ProtectedRoute));
