import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Waypoint } from 'react-waypoint';
import { FiList, FiSave } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box, Button, Flex, Icon, SimpleGrid, Tooltip } from '@chakra-ui/react';
import Banner from '@components/Banner';
import InfoBox from '@components/InfoBox';
import Preloader from '@components/Preloader';
import TextField from '@components/Form/TextField';
import routes from '@routes';
import { MediumHeading, PageSection } from '@styles/globalStyles';
import theme from '@styles/theme';
import Modal from '@components/Modal';

const Search = ({
  suppliersStore: {
    searchResults: { isLoading, data, meta, currentQuery },
    getSearchResults,
  },
  authStore: { isAuthenticated, guest },
  contactStore: {
    onConnectAuthBuyerWithSupplier,
    onConnectAnonymousBuyerWithSupplier,
    connectAnonymousUserWithSupplierForm,
  },
  profileStore: { uuid },
}) => {
  const { register, handleSubmit } = useForm();
  const {
    register: registerConnect,
    handleSubmit: handleSubmitConnect,
  } = useForm();

  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false);
  const [isModalDescriptionOpen, setIsModalDescriptionOpen] = useState(false);
  const [currentSupplierDetails, setCurrentSupplierDetails] = useState({});
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedSlug, setSelectedSlug] = useState('');

  useEffect(() => {
    getSearchResults();
  }, [getSearchResults]);

  if (!isAuthenticated && !guest.buyer) {
    return <Redirect to={routes.searchFilter} />;
  }

  const onSearchSubmit = queryData => getSearchResults(queryData.query);

  const handleObserver = () => {
    if (currentQuery !== '' && !isLoading && meta) {
      getSearchResults(currentQuery, meta.next);
    }
  };

  const connectButtonHandler = (supplierSlug, company) => {
    if (isAuthenticated) {
      onConnectAuthBuyerWithSupplier(supplierSlug);
    } else {
      setSelectedCompany(company);
      setSelectedSlug(supplierSlug);
      setIsModalConnectOpen(!isModalConnectOpen);
      setIsModalDescriptionOpen(false);
    }
  };

  const clickInfoBoxContainerHandler = selectedSupplier => {
    setCurrentSupplierDetails(selectedSupplier);
    setIsModalDescriptionOpen(!isModalDescriptionOpen);
  };

  return (
    <>
      <Banner title="Hand-picked Suppliers!" />
      <PageSection mt="40px" pt="40px">
        <Flex
          w="full"
          mb="40px"
          alignItems={['normal', 'flex-end']}
          direction={['column', 'row']}
        >
          <Box as="form" flex={1} onSubmit={handleSubmit(onSearchSubmit)}>
            <TextField
              register={register}
              name="query"
              label="Search"
              placeholder="Type a company name or a postcode"
              sx={{ mb: 0 }}
              mb="0"
            />
          </Box>
          <Tooltip
            label={
              <Box as="span" display="inline-block" p={2}>
                Refine search
              </Box>
            }
            hasArrow
          >
            <Button
              as={Link}
              to={routes.searchFilter}
              variant="outline"
              h="58px"
              px={6}
              ml={[0, 4]}
              mt={[2, 0]}
              color={theme.borders.primary}
            >
              Filter <Icon as={FiList} ml={4} />
            </Button>
          </Tooltip>
          {!isAuthenticated && guest.buyer && (
            <Button
              as={Link}
              to={routes.signUp}
              variant="outline"
              h="58px"
              px={6}
              ml={[0, 4]}
              mt={[2, 0]}
              color={theme.borders.primary}
            >
              Save search <Icon as={FiSave} ml={4} />
            </Button>
          )}
        </Flex>

        <Modal
          isOpenModal={
            isModalConnectOpen &&
            !connectAnonymousUserWithSupplierForm.isSendingSuccessful
          }
          onClose={() => setIsModalConnectOpen(!isModalConnectOpen)}
        >
          <MediumHeading>{`Please enter email below to connect with ${selectedCompany}`}</MediumHeading>
          <form
            onSubmit={handleSubmitConnect(connect =>
              onConnectAnonymousBuyerWithSupplier(connect, selectedSlug, uuid),
            )}
          >
            <Flex
              width="full"
              alignItems="center"
              flexDirection="column"
              mt="14px"
            >
              <TextField
                register={registerConnect}
                name="email"
                label="E-mail"
                type="email"
                mb="10px"
                placeholder="Type your E-mail address"
                lineHeight="18px"
                required
              />
              <Button
                w="full"
                minH="56px"
                fontSize="21px"
                type="submit"
                isLoading={connectAnonymousUserWithSupplierForm.isLoading}
                loadingText="Sending"
              >
                Send
              </Button>
            </Flex>
          </form>
        </Modal>

        <Modal
          isOpenModal={isModalDescriptionOpen}
          onClose={() => setIsModalDescriptionOpen(!isModalDescriptionOpen)}
          version="wide"
        >
          <InfoBox
            {...currentSupplierDetails}
            withConnectButton
            isOnModal
            onConnectButtonClick={connectButtonHandler}
          />
        </Modal>

        {!isLoading && data && data.length === 0 ? (
          <Box>No results found based on your search criteria.</Box>
        ) : (
          <SimpleGrid
            columns={[1, 1, 2, 3]}
            rowGap="30px"
            ml="-15px"
            mr="-15px"
          >
            {data.map((sup, index) => (
              <InfoBox
                key={`search-result-${index}`}
                {...sup}
                withConnectButton
                onConnectButtonClick={connectButtonHandler}
                onContainerClick={() => {
                  clickInfoBoxContainerHandler(sup);
                }}
              />
            ))}
          </SimpleGrid>
        )}

        {isLoading && <Preloader />}
        {currentQuery && meta && !meta.last && (
          <Flex justify="center">
            <Waypoint onEnter={handleObserver} scrollableAncestor="window">
              <Button
                to={routes.search}
                onClick={() => getSearchResults(currentQuery, meta.next)}
                variant="outline"
                mt="45px"
                minW="248px"
                minH="56px"
              >
                Show more suppliers
              </Button>
            </Waypoint>
          </Flex>
        )}
      </PageSection>
    </>
  );
};

Search.propTypes = {
  suppliersStore: PropTypes.shape({
    searchResults: PropTypes.shape({
      data: PropTypes.array.isRequired,
      isLoading: PropTypes.bool.isRequired,
      meta: PropTypes.object,
      currentQuery: PropTypes.string,
    }).isRequired,
    getSearchResults: PropTypes.func.isRequired,
  }).isRequired,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    guest: PropTypes.object.isRequired,
  }).isRequired,
  contactStore: PropTypes.shape({
    onConnectAuthBuyerWithSupplier: PropTypes.func.isRequired,
    onConnectAnonymousBuyerWithSupplier: PropTypes.func.isRequired,
    connectAnonymousUserWithSupplierForm: PropTypes.shape({
      isLoading: PropTypes.bool.isRequired,
      isSendingSuccessful: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  profileStore: PropTypes.object.isRequired,
};

export default inject(
  'suppliersStore',
  'authStore',
  'contactStore',
  'profileStore',
)(observer(Search));
