import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from '@routes';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router';
import { Button, Divider, Flex, IconButton } from '@chakra-ui/react';
import HamburgerIcon from '@assets/images/icons/hamburgerIcon.svg';
import CloseIcon from '@assets/images/icons/closeIcon.svg';
import LOGO from '@assets/images/logos/buyer-supplier-logo.svg';
import {
  Wrapper,
  Container,
  Logo,
  HamburgerWrapper,
  Navigation,
  NavButton,
  MobileLink,
  AuthNavigation,
  NavigationLink,
  NavigationCustomLink,
  MobileMenuWrapper,
} from '@components/NavBar/style';
import useBreakpoint from '@hooks/useBreakpoint';

const NavBar = ({ authStore: { isAuthenticated, logout } }) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const blogLink = '//blog.buyersupplier.co.uk';

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <Wrapper>
      <Container as={Flex}>
        <Logo to={routes.base}>
          <img src={LOGO} alt="Buyer Supplier" />
        </Logo>
        {isMobile ? (
          <>
            <HamburgerWrapper>
              <IconButton
                variant="ghost"
                onClick={() => setIsOpen(!isOpen)}
                _hover={{
                  bg: 'transparent',
                }}
                icon={<img src={isOpen ? CloseIcon : HamburgerIcon} alt="" />}
              />
            </HamburgerWrapper>
            {isOpen && (
              <MobileMenuWrapper>
                <Flex direction="column" alignItems="center" my="34px">
                  <MobileLink to={routes.procurementService}>
                    Procurement Service
                  </MobileLink>
                  <MobileLink to={routes.aboutUs}>About us</MobileLink>
                  <MobileLink to={routes.imSupplier}>I’m a Supplier</MobileLink>
                  <MobileLink to={routes.faq}>FAQ</MobileLink>
                  <MobileLink to={routes.contactUs}>Contact us</MobileLink>
                  <NavButton href={blogLink} target="_blank" rel="noreferrer">
                    Blog
                  </NavButton>
                  <MobileLink to={routes.termsAndConditions}>
                    Terms&Conditions
                  </MobileLink>
                  <MobileLink to={routes.privacyPolicy}>
                    Privacy Policy
                  </MobileLink>
                  <Divider my="34px" borderColor="#ADBEC0" />
                  {isAuthenticated ? (
                    <>
                      <MobileLink to={routes.profile}>Profile</MobileLink>
                      <Button
                        variant="link"
                        color="emph"
                        fontWeight={400}
                        fontSize="28px"
                        lineHeight="54px"
                        onClick={logout}
                      >
                        Logout
                      </Button>
                    </>
                  ) : (
                    <>
                      <MobileLink to={routes.auth.signIn}>Log in</MobileLink>
                      <MobileLink to={routes.signUp}>Sign up</MobileLink>
                    </>
                  )}
                </Flex>
              </MobileMenuWrapper>
            )}
          </>
        ) : (
          <Navigation>
            <NavButton as={NavLink} to={routes.procurementService}>
              Procurement Service
            </NavButton>
            <NavButton as={NavLink} to={routes.aboutUs}>
              About us
            </NavButton>
            <NavButton as={NavLink} to={routes.imSupplier}>
              I’m a Supplier
            </NavButton>
            <NavButton as={NavLink} to={routes.faq}>
              FAQ
            </NavButton>
            <NavButton href={blogLink} target="_blank" rel="noreferrer">
              Blog
            </NavButton>
            <NavButton as={NavLink} to={routes.contactUs}>
              Contact us
            </NavButton>
            <AuthNavigation>
              {isAuthenticated ? (
                <>
                  <NavigationLink to={routes.profile}>Profile</NavigationLink>
                  <NavigationCustomLink
                    variant="link"
                    color="emph"
                    onClick={logout}
                    ml={0}
                  >
                    Logout
                  </NavigationCustomLink>
                </>
              ) : (
                <>
                  <NavigationLink to={routes.auth.signIn}>
                    Log in
                  </NavigationLink>
                  <NavigationLink to={routes.signUp}>Sign up</NavigationLink>
                </>
              )}
            </AuthNavigation>
          </Navigation>
        )}
      </Container>
    </Wrapper>
  );
};

NavBar.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    logout: PropTypes.func,
  }).isRequired,
};

export default inject('authStore')(observer(NavBar));
