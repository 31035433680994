import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';
import { Button, Flex } from '@chakra-ui/react';
import { PageSection } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';
import theme from '@styles/theme';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${theme.navBar.height.desktop};
  background-color: ${theme.backgrounds.pageContent};
  z-index: 99;
`;

export const Container = styled(PageSection)`
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
  @media ${breakpoint.xl} {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  @media (max-width: 961px) {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 14px;
    padding-left: 14px;
  }
  @media ${breakpoint.md} {
    margin-top: 12px;
    margin-bottom: 11px;
  }
`;

export const Logo = styled(NavLink)`
  display: flex;
  width: 206px;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
  }
  @media ${breakpoint.lg} {
    width: 170px;
  }
`;

export const HamburgerWrapper = styled(Flex)`
  padding: 10px;
  align-items: center;
`;

export const Navigation = styled(Flex)`
  align-items: center;
`;

export const NavButton = styled.a`
  padding: 11px 18px 10px;
  border: 2px solid transparent;
  border-radius: 4px;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.23em;
  &.active {
    border-color: ${theme.colors.emph};
    color: ${theme.colors.emph};
  }
  @media ${breakpoint.xxl} {
    font-size: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media ${breakpoint.xl} {
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (max-width: 961px) {
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
  }
  @media ${breakpoint.md} {
    font-size: 28px;
    line-height: 54px;
    font-weight: 400;
    color: ${theme.colors.emph};
    margin: 0;
  }
`;

export const AuthNavigation = styled(Flex)`
  margin-left: 110px;
  @media ${breakpoint.xl} {
    margin-left: 66px;
  }
  @media ${breakpoint.lg} {
    margin-left: 12px;
  }
`;

export const NavigationLink = styled(NavLink)`
  font-weight: 500;
  font-size: 21px;
  line-height: 1.23em;
  color: ${theme.colors.emph};
  &:before,
  & + button:before {
    margin: 0 12px;
    content: '|';
    color: ${theme.colors.primary};
    @media ${breakpoint.lg} {
      margin: 0 6px;
    }
  }
  &:first-of-type {
    &:before {
      content: none;
    }
  }
  &.active,
  &:hover {
    text-decoration: underline;
  }
  @media ${breakpoint.xxl} {
    font-size: 20px;
  }
  @media ${breakpoint.xl} {
    font-size: 18px;
  }
  @media (max-width: 961px) {
    font-size: 14px;
  }
`;

export const NavigationCustomLink = styled(Button)`
  font-size: 21px;
  @media ${breakpoint.xxl} {
    font-size: 20px;
  }
  @media ${breakpoint.xl} {
    font-size: 18px;
  }
  @media (max-width: 961px) {
    font-size: 14px;
  }
`;

export const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: ${theme.navBar.height.desktop};
  background: white;
  z-index: -1;
  overflow: auto;
`;

export const MobileLink = styled(NavLink)`
  font-size: 28px;
  line-height: 54px;
  font-weight: 400;
  color: ${theme.colors.emph};
  margin: 0;

  &.active {
    color: ${theme.colors.primary};
  }
`;
