import { makeAutoObservable, runInAction } from 'mobx';
import { APIRoutes, API } from '@app/api';

export class SearchesStore {
  constructor() {
    makeAutoObservable(this);
  }

  searches = {
    isLoading: false,
    data: [],
    error: null,
  };

  getSavedSearches = async () => {
    this.searches.isLoading = true;

    try {
      const {
        data: { searches },
      } = await API.get(APIRoutes.SAVED_SEARCHES);

      runInAction(() => {
        this.searches.data = searches;
      });
    } catch (error) {
      runInAction(() => {
        this.searches.error = error;
      });
    } finally {
      runInAction(() => {
        this.searches.isLoading = false;
      });
    }
  };
}

export default new SearchesStore();
