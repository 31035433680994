import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  ImageContainer,
  TextContainer,
  TextContainerInner,
} from '@components/Banner/style';
import useBreakpoint from '@hooks/useBreakpoint';

export * from '@components/Banner/style';

const Banner = ({ children, image, size, title }) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  return (
    <Container bSize={size}>
      <TextContainer bSize={size} fullWidth={!image}>
        <TextContainerInner bSize={size} fullWidth={!image}>
          {size === 'medium' ? <h6>{title}</h6> : <h1>{title}</h1>}
          {children}
        </TextContainerInner>
      </TextContainer>
      {image && !isMobile && <ImageContainer bSize={size} image={image} />}
    </Container>
  );
};

Banner.defaultProps = {
  children: null,
  image: '',
  size: 'small',
};

Banner.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Banner;
