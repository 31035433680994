import procurementValue1 from '@assets/images/banners/procurementValue1.png';
import procurementValue2 from '@assets/images/banners/procurementValue2.png';
import procurementValue3 from '@assets/images/banners/procurementValue3.png';

export const procurementValues = [
  {
    id: 1,
    label: 'Objective Efficient process',
    image: procurementValue1,
    subsections: [
      {
        id: 1,
        content: 'Proven briefing process and tender <br /> documentation',
      },
      { id: 2, content: 'Willing and capable suppliers' },
      { id: 3, content: 'Objective tender analysis and <br /> recommendation' },
    ],
  },
  {
    id: 2,
    label: 'Independent Advice',
    image: procurementValue2,
    subsections: [
      {
        id: 1,
        content: 'Not affiliated to any supplier',
      },
      {
        id: 2,
        content:
          'Procurement delivered through an RICS <br /> accredited company',
      },
      {
        id: 3,
        content:
          'Independently supported the procurement of <br /> over £1 billion worth of  facilities services',
      },
    ],
  },
  {
    id: 3,
    label: 'Demonstrable Value',
    image: procurementValue3,
    subsections: [
      {
        id: 1,
        content: 'Obtain a minimum of 3 quotes',
      },
      { id: 2, content: 'Tenders evaluated for cost, quality and risk' },
      {
        id: 3,
        content:
          'Involve you as much or as little in the <br /> evaluation process as you would like.',
      },
    ],
  },
];
