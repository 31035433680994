import { Box, Button, Flex } from '@chakra-ui/react';
import FormError from '@components/Form/FormMessage';
import TextField from '@components/Form/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const ResetPasswordConfirmationForm = ({ onSubmit, isLoading, token }) => {
  const schema = yup
    .object()
    .shape({
      password: yup
        .string()
        .required('Password is required')
        .min(8, 'Password should have a minimum of 8 characters')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          'Password should be a mix of uppercase, lowercase, digits',
        ),
      passwordConfirmation: yup
        .string()
        .required('Password confirmation is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    .required();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const inputProps = {
    register,
    errors,
    required: true,
    sx: {
      marginBottom: '40px',
    },
  };

  const onSubmitHandler = e => {
    clearErrors('general');
    handleSubmit(data => onSubmit(data, setError))(e);
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Flex width="full" alignItems="center" flexDirection="column">
        <FormError message={errors.general?.message} />
        <Box
          width="full"
          mb={3}
          sx={{
            position: 'relative',
          }}
        >
          <TextField
            label="Password"
            type="password"
            name="password"
            {...inputProps}
          />
        </Box>
        <Box
          width="full"
          mb={3}
          sx={{
            position: 'relative',
          }}
        >
          <TextField
            label="Password confirmation"
            type="password"
            name="passwordConfirmation"
            {...inputProps}
          />
        </Box>
        {token && (
          <TextField
            label="token"
            type="hidden"
            name="token"
            value={token}
            readOnly
            register={register}
          />
        )}
        <Button type="submit" w="full" minH="56px" isLoading={isLoading}>
          Change password
        </Button>
      </Flex>
    </form>
  );
};

ResetPasswordConfirmationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

export default ResetPasswordConfirmationForm;
