import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SignUpBuyer from '@containers/SignUpBuyer';
import SignUpSupplier from '@containers/SignUpSupplier';

const ProfileSettings = ({
  authStore: {
    user: { userType, supplier, buyer },
  },
}) => {
  if (userType === 'supplier') {
    return <SignUpSupplier initialData={supplier} />;
  }

  if (userType === 'buyer') {
    return <SignUpBuyer initialData={buyer} />;
  }

  return null;
};

ProfileSettings.propTypes = {
  authStore: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
};

export default inject('authStore')(observer(ProfileSettings));
