import React from 'react';
import {
  Box,
  Button,
  VStack,
  ListItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import Banner from '@components/Banner';
import {} from '@containers/ImSupplier/style';
import {
  FinePrint,
  PresentaionBox,
  PresentaionBoxContent,
  PresentaionBoxHeader,
} from '@containers/Payment/style';
import {
  UnorderedListStyled,
  ListItemStyled,
  PageSection,
} from '@styles/globalStyles';

const Payment = () => (
  <>
    <Banner title="Choose a Supplier Plan" />
    <PageSection>
      <SimpleGrid
        columns={[1, 1, 1, 3]}
        columnGap={['20px', '20px', '20px', 0, '20px']}
        rowGap="50px"
        mt="50"
        mb="20px"
        ml="-15px"
        mr="-15px"
      >
        <PresentaionBox>
          <PresentaionBoxHeader grey>
            <h1>Basic</h1>
          </PresentaionBoxHeader>
          <PresentaionBoxContent>
            <UnorderedListStyled spacing="30px">
              <ListItemStyled as={ListItem}>
                Unlimited potential matches with end buyers
              </ListItemStyled>
              <ListItemStyled as={ListItem}>
                Your company name and logo are included in the search
              </ListItemStyled>
              <ListItemStyled as={ListItem}>
                Your website details are shown to buyers
              </ListItemStyled>
              <ListItemStyled as={ListItem}>Web support</ListItemStyled>
            </UnorderedListStyled>
          </PresentaionBoxContent>
          <PresentaionBoxContent mt="56px">
            <Text
              color="#8D8D8D"
              fontSize="48px"
              textAlign="center"
              lineHeight="2em"
            >
              FREE
            </Text>
          </PresentaionBoxContent>
        </PresentaionBox>
        <PresentaionBox>
          <PresentaionBoxHeader>
            <h1>Monthly</h1>
          </PresentaionBoxHeader>
          <PresentaionBoxContent>
            <UnorderedListStyled spacing="30px">
              <ListItemStyled as={ListItem}>
                Everything in{' '}
                <Text as="span" fontWeight="bold">
                  Basic
                </Text>
              </ListItemStyled>
              <ListItemStyled as={ListItem}>
                Buyers connect with you in{' '}
                <Text as="span" fontWeight="bold">
                  one click
                </Text>
              </ListItemStyled>
              <ListItemStyled as={ListItem}>
                You receive specific details of each buyer project (size,
                budget, location etc.)
              </ListItemStyled>
              <ListItemStyled as={ListItem}>
                Opportunity for ongoing warm leads for your dealership
              </ListItemStyled>
            </UnorderedListStyled>
          </PresentaionBoxContent>
          <PresentaionBoxContent>
            <Text
              mt="30px"
              fontSize="48px"
              textAlign="center"
              color="emph"
              fontWeight="500"
            >
              £60
            </Text>
            <Text color="#8D8D8D" fontSize="24px" textAlign="center">
              Per Month*
            </Text>
          </PresentaionBoxContent>
          <PresentaionBoxContent textAlign="center">
            <Button
              as="a"
              href={process.env.REACT_APP_STRIPE_LINK_MONTHLY}
              target="_blank"
              rel="noopener"
              h="56px"
              justifyContent="center"
              w="100%"
              maxW="248px"
              fontSize={18}
            >
              Buy now
            </Button>
          </PresentaionBoxContent>
        </PresentaionBox>
        <PresentaionBox as={VStack} justifyContent="space-between">
          <Box w="100%">
            <PresentaionBoxHeader>
              <h1>Annual</h1>
            </PresentaionBoxHeader>
            <PresentaionBoxContent>
              <UnorderedListStyled spacing="30px">
                <ListItemStyled as={ListItem}>
                  Everything in{' '}
                  <Text as="span" fontWeight="bold">
                    Monthly
                  </Text>
                </ListItemStyled>
              </UnorderedListStyled>
            </PresentaionBoxContent>
          </Box>
          <Box w="100%">
            <PresentaionBoxContent textAlign="center">
              at a{' '}
              <Text as="span" color="emph" fontWeight="500">
                discounted annual rate
              </Text>
            </PresentaionBoxContent>
            <PresentaionBoxContent
              textAlign="center"
              fontWeight="500"
              mt="70px"
            >
              <Text as="span" fontSize="40px">
                SAVE{' '}
              </Text>
              <Text as="span" color="emph" textAlign="center" fontSize="44px">
                31%
              </Text>
            </PresentaionBoxContent>
          </Box>
          <Box w="100%">
            <Box>
              <PresentaionBoxContent>
                <Text
                  mt="30px"
                  fontSize="48px"
                  textAlign="center"
                  color="emph"
                  fontWeight="500"
                >
                  £495
                </Text>
                <Text color="#8D8D8D" fontSize="24px" textAlign="center">
                  Per Annum*
                </Text>
              </PresentaionBoxContent>
              <PresentaionBoxContent textAlign="center">
                <Button
                  as="a"
                  href={process.env.REACT_APP_STRIPE_LINK_ANNUAL}
                  target="_blank"
                  rel="noopener"
                  h="56px"
                  justifyContent="center"
                  w="100%"
                  maxW="248px"
                  fontSize={18}
                >
                  Buy now
                </Button>
              </PresentaionBoxContent>
            </Box>
          </Box>
        </PresentaionBox>
      </SimpleGrid>
      <FinePrint>
        <Box>
          <Text>* Price does not include VAT.</Text>
          <Text>Subscriptions renew automatically</Text>
        </Box>
      </FinePrint>
    </PageSection>
  </>
);

export default Payment;
