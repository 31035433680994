/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, SimpleGrid } from '@chakra-ui/react';
import Checkbox from '@components/Form/Checkbox';
import { Title, FieldWrapper, Asterix } from '@components/Form/style';

const CheckboxGroup = ({
  title,
  options,
  name,
  register,
  unregister,
  required,
  rules,
  errors,
  sx,
  isGird,
  direction,
  ...rest
}) => {
  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  let CheckboxWrapper = props => (
    <Flex
      flexDirection={{
        base: 'column',
        md: direction,
      }}
      flexWrap="wrap"
      {...props}
    />
  );

  if (isGird) {
    CheckboxWrapper = props => (
      <SimpleGrid gap={4} columns={[1, 2, 3, 4, 5]} {...props} />
    );
  }

  return (
    <FieldWrapper sx={sx}>
      {title && (
        <Title>
          {title} {required && <Asterix>*</Asterix>}
        </Title>
      )}
      <CheckboxWrapper>
        {options.map((option, index) => {
          const label = typeof option === 'string' ? option : option.label;
          const value = typeof option === 'string' ? option : option.value;

          return (
            <div key={`${value}-${index}`}>
              <Checkbox
                {...{
                  register,
                  name,
                  value,
                  label,
                  required,
                  errors,
                  ofGroup: true,
                  ...rest,
                }}
              />
            </div>
          );
        })}
      </CheckboxWrapper>
    </FieldWrapper>
  );
};

CheckboxGroup.defaultProps = {
  title: undefined,
  required: false,
  errors: {},
  sx: {},
  rules: {},
  direction: 'row',
  options: [],
  unregister: () => {},
  isGird: false,
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func,
  required: PropTypes.bool,
  options: PropTypes.array,
  errors: PropTypes.object,
  rules: PropTypes.object,
  sx: PropTypes.object,
  direction: PropTypes.oneOf(['column', 'row']),
  isGird: PropTypes.bool,
};

export default CheckboxGroup;
