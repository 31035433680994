/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, HStack, Text } from '@chakra-ui/react';
import PLACEHOLDER from '@assets/images/suppliers/placeholder.png';
import {
  BadgeStyled,
  Label,
  EmphData,
  InfoBoxWrapper,
  LogoWrapper,
} from '@components/InfoBox/style';

const InfoBox = ({
  slug,
  company,
  city,
  postcode,
  website,
  price_range,
  description,
  case_study,
  logo_url,
  relevance_score_percent,
  matches,
  withConnectButton,
  isOnModal,
  onConnectButtonClick,
  onContainerClick,
}) => {
  const [isConnectClicked, setIsConnectClicked] = useState(false);
  return (
    <InfoBoxWrapper
      withConnectButton={withConnectButton}
      isOnModal={isOnModal}
      onClick={onContainerClick}
    >
      <HStack spacing="20px" justify="space-between">
        <LogoWrapper>
          <img src={logo_url || PLACEHOLDER} alt="" />
        </LogoWrapper>
        {relevance_score_percent && (
          <EmphData>{relevance_score_percent}%</EmphData>
        )}
      </HStack>
      {company && (
        <Text
          fontSize="18px"
          fontWeight="700"
          lineHeight="1.22em"
          mt="16px"
          mb="5px"
        >
          {company}
        </Text>
      )}
      {city && (
        <Text fontSize="16px" lineHeight="1.4em">
          <Label>City:</Label>
          {city}
        </Text>
      )}
      {postcode && (
        <Text fontSize="16px" lineHeight="1.4em">
          <Label>Postcode:</Label>
          {postcode}
        </Text>
      )}
      {website && (
        <Text fontSize="16px" lineHeight="1.4em">
          <Label>Web:</Label>
          <a
            href={website}
            className="link"
            target="_blank"
            onClick={e => e.stopPropagation()}
            rel="noreferrer"
          >
            {website}
          </a>
        </Text>
      )}
      {price_range && (
        <Text fontSize="16px" lineHeight="1.4em">
          <Label>Price range:</Label>
          {price_range}
        </Text>
      )}
      {isOnModal && description && (
        <Flex fontSize="16px" lineHeight="1.4em">
          <Label>Description:</Label>
          {description}
        </Flex>
      )}
      {isOnModal && case_study && (
        <Text fontSize="16px" lineHeight="1.4em">
          <Label>Case study:</Label>
          <a
            href={case_study}
            className="link"
            target="_blank"
            onClick="event.stopPropagation()"
            rel="noreferrer"
          >
            {case_study}
          </a>
        </Text>
      )}
      {matches?.length > 0 && (
        <Flex wrap="wrap">
          {matches.map((badge, i) => (
            <BadgeStyled key={`badge-${i}`} mt="10px" mr="10px">
              {badge}
            </BadgeStyled>
          ))}
        </Flex>
      )}
      {withConnectButton && (
        <Button
          isDisabled={isConnectClicked}
          variant="outline"
          minW="161px"
          fontSize="18px"
          lineHeight="1.23em"
          position="absolute"
          bottom={30}
          right={30}
          onClick={e => {
            e.stopPropagation();
            onConnectButtonClick(slug, company);
            setIsConnectClicked(true);
          }}
        >
          Connect
        </Button>
      )}
    </InfoBoxWrapper>
  );
};

InfoBox.defaultProps = {
  slug: null,
  company: null,
  postcode: null,
  city: null,
  website: null,
  price_range: null,
  description: null,
  case_study: null,
  logo_url: null,
  relevance_score_percent: null,
  matches: null,
  withConnectButton: false,
  isOnModal: false,
  onConnectButtonClick: () => {},
  onContainerClick: () => {},
};

InfoBox.propTypes = {
  slug: PropTypes.string,
  company: PropTypes.string,
  postcode: PropTypes.string,
  city: PropTypes.string,
  website: PropTypes.string,
  price_range: PropTypes.string,
  description: PropTypes.string,
  case_study: PropTypes.string,
  logo_url: PropTypes.string,
  relevance_score_percent: PropTypes.number,
  matches: PropTypes.arrayOf(PropTypes.string),
  withConnectButton: PropTypes.bool,
  isOnModal: PropTypes.bool,
  onConnectButtonClick: PropTypes.func,
  onContainerClick: PropTypes.func,
};

export default InfoBox;
