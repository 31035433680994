import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Box, Button, ListItem, Text } from '@chakra-ui/react';
import Banner from '@components/Banner';
import {
  ButtonBgWrapper,
  Container,
  ContentLeft,
  ContentRight,
} from '@containers/ImSupplier/style';
import routes from '@routes';
import {
  PageSection,
  UnorderedListStyled,
  ListItemStyled,
} from '@styles/globalStyles';

const ImSupplier = ({ authStore: { setIsPotentialSupplier } }) => (
  <>
    <Banner title="Benefits to Supplier" />
    <PageSection>
      <Text
        as="h2"
        fontWeight="600"
        fontSize="28px"
        fontFamily="'Nunito', sans-serif"
        mt={['40px', '88px']}
        mb="20px"
        lineHeight="1.2em"
      >
        Benefits of being a Supplier
      </Text>

      <UnorderedListStyled spacing="30px">
        <ListItemStyled as={ListItem}>
          <Text fontWeight="bold">
            UK’s First dedicated furniture dealership comparison site.
          </Text>
          <Text>
            We match the UK’s best office furniture dealerships with B2B buyers
            who have live projects and are looking for your services.
          </Text>
        </ListItemStyled>
        <ListItemStyled as={ListItem}>
          <Text fontWeight="bold">Better Leads for your business.</Text>
          <Text>
            Our goal is to provide relevant warm leads for your business. All
            leads relate to the buyer’s defined project requirements and are
            matched to your specific capabilities.
          </Text>
        </ListItemStyled>
        <ListItemStyled as={ListItem}>
          <Text fontWeight="bold">More Visibility to the market.</Text>
          <Text>
            We are an industry specific platform, designed for anyone interested
            in purchasing B2B furniture. We specifically target our marketing at
            companies who have current furniture projects.
          </Text>
        </ListItemStyled>
      </UnorderedListStyled>
    </PageSection>

    <Container>
      <ContentLeft>
        <Text as="h2" fontWeight="600" fontSize="28px" mb="20px">
          So, how does BuyerSupplier work?
        </Text>
        <Text mb="28px">
          We have good information from you, the supplier, which is relevant to
          the buyer’s dealership selection process.
        </Text>
        <Text mb="28px">
          Simply answer our questionnaire about yourselves and the projects you
          like to deliver, and you will appear in searches from buyers actively
          looking for projects.
        </Text>
        <Text mb="28px">
          After the buyer completes their project details, we provide the buyer
          with the 20 results that most closely match their requirements,
          including the percentage match and company name.
        </Text>
        <Text>
          We then provide the buyers with the opportunity to connect with their
          chosen dealerships.
        </Text>

        <Text as="h2" fontWeight="600" fontSize="28px" mb="20px" mt="60px">
          Subscription Options
        </Text>
        <Text mb="28px">
          We offer a monthly or annual subscription service. For these premium
          subscriptions, buyers will be able to directly connect and provide
          their project details; this enables you to fully understand the
          project scope and contact the buyer directly.
        </Text>
        <Text mb="28px">
          We also offer a free profile that will show purchasers your company
          name and score in searches where you are in the top 20 matches.
          However this option does not have a connect button and does not
          directly send through the project details.
        </Text>

        <Text as="h2" fontWeight="600" fontSize="28px" mb="20px" mt="60px">
          Registration
        </Text>
        <Text mb="28px">Simply click the link to register.</Text>
        <Text mb="28px">
          Complete the questionnaire in the registration process. The more
          questions you answer the more likely you are to match with our buyers.
        </Text>
        <Text>
          You can update your details whenever you want by logging in to your
          account. We appreciate things change and it is important to our buyers
          that you are sharing your most up to date information.
        </Text>
      </ContentLeft>
      <ContentRight>
        <ButtonBgWrapper>
          <Box>
            <Button
              as={Link}
              to={routes.signUp}
              flex={1}
              variant="ghost"
              minW="248px"
              minH="56px"
              fontSize="18px"
              lineHeight="1.23em"
              backgroundColor="#fff"
              onClick={() => setIsPotentialSupplier(true)}
            >
              Register For Free
            </Button>
          </Box>
        </ButtonBgWrapper>
      </ContentRight>
    </Container>
  </>
);

ImSupplier.propTypes = {
  authStore: PropTypes.object.isRequired,
};

export default inject('authStore')(observer(ImSupplier));
