import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import {
  Checkmark,
  InputWrapper,
  StyledInput,
  StyledText,
} from '@components/Form/Checkbox/style';
import { Asterix, FieldWrapper } from '@components/Form/style';

const Checkbox = ({
  defaultChecked,
  name,
  register,
  required,
  rules,
  errors,
  ofGroup,
  label,
  afterLabel,
  value,
  ...rest
}) => {
  const fieldId = useMemo(() => uniqueId(`input_${name}_`), []);

  const { onBlur, onChange, ref } = register(name, {
    ...(required && { required: 'Required' }),
    ...rules,
  });

  return (
    <FieldWrapper mb={ofGroup ? 0 : '60px'}>
      <InputWrapper withError={errors[name] ? 1 : 0}>
        <StyledInput htmlFor={fieldId} ofGroup={ofGroup}>
          <input
            {...{
              type: 'checkbox',
              id: fieldId,
              name,
              value,
              onBlur,
              onChange,
              onClick: onChange,
              ref,
              defaultChecked,
              ...rest,
            }}
          />
          <StyledText>
            {label} {afterLabel || ''}{' '}
            {required && !ofGroup && <Asterix>*</Asterix>}
          </StyledText>

          <Checkmark />
        </StyledInput>
      </InputWrapper>
    </FieldWrapper>
  );
};

Checkbox.defaultProps = {
  afterLabel: null,
  required: false,
  errors: {},
  rules: {},
  value: undefined,
  ofGroup: false,
  onChange: undefined,
  defaultChecked: false,
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  afterLabel: PropTypes.node,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errors: PropTypes.object,
  rules: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ofGroup: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
