import React from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Box, UnorderedList, Text } from '@chakra-ui/react';
import reset from 'emotion-reset';
import ARROW from '@assets/images/shapes/arrow.svg';
import { breakpoint } from '@styles/breakpoints';

import CHEVRON from '@assets/images/logos/favicon-32x32.png';

const GlobalStyle = () => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;1,700&display=swap');
      ${reset}
      *,
      *:after,
      *:before {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      body {
        color: #1d1d1f;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
      }
      a {
        text-decoration: none;
        color: inherit;
      }
      .link {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        color: #2b7a77;
      }
      b {
        font-weight: 500;
      }
      h1,
      h2,
      h3,
      h4,
      h6 {
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
      }
      h1 {
        font-size: 62px;
        line-height: 1.08em;
        @media ${breakpoint.lg} {
          font-size: 50px;
        }
        @media ${breakpoint.md} {
          font-size: 40px;
        }
        @media ${breakpoint.xs} {
          font-size: 30px;
        }
      }
      h2 {
        font-size: 30px;
        line-height: 1.2em;
        @media ${breakpoint.md} {
          font-size: 26px;
        }
      }
      h3 {
        font-size: 42px;
        line-height: 1.08em;
        @media ${breakpoint.md} {
          font-size: 30px;
        }
      }
      h4 {
        font-size: 28px;
        line-height: 1.2em;
        font-weight: 600;
      }
      h6 {
        font-size: 42px;
        line-height: 1.08em;
        @media ${breakpoint.md} {
          font-size: 40px;
        }
      }

      .hide-on-mobile {
        @media ${breakpoint.md} {
          display: none;
        }
      }

      .slick-slider {
        position: relative;
        margin: 0 auto;
        user-select: none;
        touch-action: pan-y;
        @media ${breakpoint.xxl} {
          width: 88%;
        }
        @media ${breakpoint.xl} {
          width: 90%;
        }
        @media ${breakpoint.lg} {
          width: 88%;
        }
      }
      .slick-list {
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 0;
        transform: translateZ(0);
        box-sizing: initial;
        padding: 25px 0px;
      }
      .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        transform: translateZ(0);
      }
      .slick-slide {
        display: none;
        float: left;
        height: 100%;
      }
      .slick-initialized .slick-slide {
        display: block;
      }
      .slick-arrow {
        position: absolute;
        top: 0;
        width: 45px;
        height: 100%;
        position: absolute;
        padding: 0 10px;
        cursor: pointer;
        border: none;
        font-size: 0;
        line-height: 0;
        &:before {
          display: block;
          width: 100%;
          height: 100%;
          content: '';
          background-image: url(${ARROW});
          background-repeat: no-repeat;
          background-position: center;
        }
        &,
        &:focus,
        &:hover {
          outline: none;
        }
      }
      .slick-prev {
        left: -80px;

        &:before {
          transform: rotate(180deg);
        }
        @media ${breakpoint.xl} {
          left: -40px;
        }

        @media ${breakpoint.md} {
          left: -30px;
        }
      }
      .slick-next {
        right: -80px;

        @media ${breakpoint.xl} {
          right: -40px;
        }

        @media ${breakpoint.md} {
          right: -30px;
        }
      }

      @keyframes bounceLeft {
        0% {
          transform: scale(1, 1) translateX(0);
        }
        10% {
          transform: scale(1.1, 0.9) translateX(0);
        }
        30% {
          transform: scale(0.9, 1.1) translateX(-40px);
        }
        50% {
          transform: scale(1.05, 0.95) translateX(0);
        }
        57% {
          transform: scale(1, 1) translateX(-7px);
        }
        64% {
          transform: scale(1, 1) translateX(0);
        }
        100% {
          transform: scale(1, 1) translateX(0);
        }
      }
      @keyframes bounceRight {
        0% {
          transform: scale(1, 1) translateX(0);
        }
        10% {
          transform: scale(1.1, 0.9) translateX(0);
        }
        30% {
          transform: scale(0.9, 1.1) translateX(40px);
        }
        50% {
          transform: scale(1.05, 0.95) translateX(0);
        }
        57% {
          transform: scale(1, 1) translateX(7px);
        }
        64% {
          transform: scale(1, 1) translateX(0);
        }
        100% {
          transform: scale(1, 1) translateX(0);
        }
      }
    `}
  />
);

const PageSectionCalcWidth = ({ theme }) =>
  `${theme.page.pageWidth} + 2 * ${theme.page.sidePadding}`;

export const PageSection = styled(Box)`
  margin-right: auto;
  margin-left: auto;
  padding-right: ${({ theme }) => theme.page.sidePadding};
  padding-left: ${({ theme }) => theme.page.sidePadding};
  max-width: calc(${PageSectionCalcWidth});
`;

export const FullBgBox = props => (
  <Box
    position="relative"
    _before={{
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '-50vw',
      right: '-50vw',
      bg: 'bgLight',
    }}
    {...props}
  />
);

export const StaticContent = styled(Box)`
  line-height: 1.34em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 22px;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.35;
  }

  ol,
  ul {
    list-style: unset;
    margin: 10px 20px 24px;
  }

  .faq {
    margin-bottom: 30px;
  }
`;

export const MediumHeading = styled(Text)`
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2em;
`;

export const NoOverflowWrapper = styled.div`
  overflow-x: hidden;
`;

export const UnorderedListStyled = styled(UnorderedList)`
  list-style-type: none;
  margin-top: 40px;
  margin-left: 44px;
  line-height: 1.6em;
`;

export const ListItemStyled = styled(Text)`
  position: relative;
  margin-top: 16px;
  &:before {
    position: absolute;
    left: -46px;
    width: 32px;
    height: 32px;
    content: url(${CHEVRON});
  }
`;

export default GlobalStyle;
