import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const PreloaderWrapper = styled.div`
  position: relative;
  width: 72px;
  height: 72px;
  margin: 40px auto;
`;

const orbit = keyframes`
	0% {
		z-index: 99;
		transform: rotate(180deg);
		animation-timing-function: ease-out;
	}

	7% {
		transform: rotate(300deg);
		animation-timing-function: linear;
		origin: 0%;
	}

	30% {
		transform: rotate(410deg);
		animation-timing-function: ease-in-out;
		origin: 7%;
	}

	39% {
		transform: rotate(645deg);
		animation-timing-function: linear;
		origin: 30%;
	}

	70% {
		transform: rotate(770deg);
		animation-timing-function: ease-out;
		origin: 39%;
	}

	95% {
		transform: rotate(900deg);
		animation-timing-function: ease-out;
		origin: 70%;
	}

	100% {
		transform: rotate(900deg);
	}
`;

export const Ball = styled.div`
  position: absolute;
  width: 74px;
  height: 74px;
  transform: rotate(225deg);
  animation: ${orbit} 3.48s infinite;

  &#Ball_1 {
    animation-delay: 0.76s;
  }

  &#Ball_2 {
    animation-delay: 0.15s;
  }

  &#Ball_3 {
    animation-delay: 0.3s;
  }

  &#Ball_4 {
    animation-delay: 0.45s;
  }

  &#Ball_5 {
    animation-delay: 0.61s;
  }
`;

export const InnerBall = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.backgrounds.primary};
  left: 0;
  top: 0;
  border-radius: 10px;
`;
