import React from 'react';
import Slider from 'react-slick';
import { Wrapper } from '@components/InfoBoxCarousel/style';
import Service from '@components/ServicesCarousel/serviceTile';

import { services } from '@components/ServicesCarousel/services';

const CompaniesCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        {services.map(x => (
          <Service key={x.id} logo={x.image} label={x.label} />
        ))}
      </Slider>
    </Wrapper>
  );
};

export default CompaniesCarousel;
