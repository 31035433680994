import React from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Flex, Image, Text } from '@chakra-ui/react';
import LOGO from '@assets/images/logos/buyer-supplier-logo.svg';
import LINKEDIN from '@assets/images/icons/linkedin.svg';
import {
  Container,
  Content,
  Logo,
  NavButton,
  Navigation,
  Wrapper,
} from '@components/Footer/style';
import useBreakpoint from '@hooks/useBreakpoint';
import routes from '@routes';

const getCurrentYear = () => new Date().getFullYear();

const Footer = ({ authStore: { isAuthenticated, logout } }) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const blogLink = '//blog.buyersupplier.co.uk';
  const linkedinLink = 'https://www.linkedin.com/company/buyersupplier';

  return (
    <Wrapper>
      <Container as={Flex}>
        {!isMobile && (
          <Logo to={routes.base}>
            <img src={LOGO} alt="Buyer Supplier" />
          </Logo>
        )}
        <Content>
          <Navigation>
            <NavButton as={NavLink} to={routes.base}>
              Home
            </NavButton>
            <NavButton as={NavLink} to={routes.imSupplier}>
              I’m a Supplier
            </NavButton>
            <NavButton as={NavLink} to={routes.aboutUs}>
              About us
            </NavButton>
            <NavButton as={NavLink} to={routes.faq}>
              FAQ
            </NavButton>
            <NavButton href={blogLink} target="_blank" rel="noreferrer">
              Blog
            </NavButton>
            <NavButton as={NavLink} to={routes.contactUs}>
              Contact us
            </NavButton>
            <NavButton as={NavLink} to={routes.termsAndConditions}>
              Terms&Conditions
            </NavButton>
            <NavButton as={NavLink} to={routes.privacyPolicy}>
              Privacy Policy
            </NavButton>

            {isAuthenticated ? (
              <>
                {!isMobile && (
                  <NavButton as={NavLink} to={routes.profile}>
                    Profile
                  </NavButton>
                )}
                <NavButton onClick={logout}>Logout</NavButton>
              </>
            ) : (
              <>
                {!isMobile && (
                  <NavButton as={NavLink} to={routes.auth.signIn}>
                    Log in
                  </NavButton>
                )}
                <NavButton as={NavLink} to={routes.signUp}>
                  Sign up
                </NavButton>
              </>
            )}
          </Navigation>
          <Flex
            fontSize="14px"
            alignItems="center"
            justify={['center', 'center', 'flex-end']}
          >
            <Text d="inline-block" mr={[0, 0, '25px']} color="#8D8D8D">
              Copyright 2021-{getCurrentYear()} BuyerSupplier
            </Text>
            {!isMobile && (
              <a href={linkedinLink} target="_blank" rel="noreferrer">
                <Image src={LINKEDIN} alt="LinkedIn" ml="6px" />
              </a>
            )}
          </Flex>
        </Content>
      </Container>
    </Wrapper>
  );
};

Footer.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    logout: PropTypes.func,
  }).isRequired,
};

export default inject('authStore')(observer(Footer));
