import styled from '@emotion/styled/macro';
import { breakpoint } from '@styles/breakpoints';
import { PageSection } from '@styles/globalStyles';

export const Container = styled(PageSection)`
  margin-top: 52px;
  @media ${breakpoint.xl} {
    margin-top: 52px;
  }
`;
