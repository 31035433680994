import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Text } from '@chakra-ui/react';
import Sticky from 'react-stickynode';
import { Link } from 'react-scroll';

const Step = ({ id, isActive, setActiveStep }) => (
  <Box
    as={Link}
    activeClass="active"
    to={`step-${id}`}
    spy
    smooth
    offset={id === 5 ? -350 : -200}
    duration={300}
    onSetActive={setActiveStep}
    cursor="pointer"
  >
    <Center
      borderRadius="50%"
      boxSize="26px"
      bg={isActive ? '#17242A' : '#C2CED3'}
      color="white"
      mx="auto"
    >
      <Text fontSize="14px" lineHeight={1}>
        {id}
      </Text>
    </Center>

    <Text
      fontSize="12px"
      fontWeight={isActive ? '500' : '400'}
      mt="8px"
      textTransform="uppercase"
      color={isActive ? '#17242A' : '#C2CED3'}
    >
      Step {id}
    </Text>
  </Box>
);

Step.propTypes = {
  id: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

const Separator = ({ isActive }) => (
  <Box
    width="4px"
    height="18px"
    bg={isActive ? '#17242A' : '#C2CED3'}
    mx="auto"
    my="12px"
    borderRadius="4px"
  />
);

Separator.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

const StepIndicator = ({ numSteps, currentStep, ...rest }) => {
  const [activeStep, setActiveStep] = useState('step-1');

  return (
    <Box position="relative" zIndex="9">
      <Sticky enabled top={90}>
        <Box position="absolute" top="0" right="0" {...rest}>
          {Array(numSteps)
            .fill(null)
            .map((_, index) => {
              const id = index + 1;
              const isActive = activeStep === `step-${id}`;

              return (
                <Fragment key={`step-${index}`}>
                  <Step
                    id={id}
                    isActive={isActive}
                    setActiveStep={setActiveStep}
                  />
                  {id < numSteps && <Separator isActive={isActive} />}
                </Fragment>
              );
            })}
        </Box>
      </Sticky>
    </Box>
  );
};

StepIndicator.propTypes = {
  numSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default StepIndicator;
