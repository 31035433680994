import styled from '@emotion/styled/macro';
import isPropValid from '@emotion/is-prop-valid';
import { Box } from '@chakra-ui/react';
import { breakpoint } from '@styles/breakpoints';

export const InfoBoxWrapper = styled(Box, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  position: relative;
  margin: 0 15px;
  padding: 30px;
  min-height: 290px;
  border: 1px solid ${({ theme }) => theme.borders.secondary};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.backgrounds.pageContent};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  ${({ isOnModal, withConnectButton }) =>
    withConnectButton === true &&
    isOnModal !== true &&
    `
    padding-bottom: 84px;
    transition: all 0.2s ease;
    &:hover {
      transform: scale(1.05);
      transition: all 0.2s ease;
      cursor: pointer;
    }
  `};
  ${({ isOnModal }) =>
    isOnModal === true &&
    `
    margin: 0;
    padding-bottom: 84px;
    @media ${breakpoint.md} {
      overflow: auto;
    }
  `};
`;

export const LogoWrapper = styled(Box)`
  height: 56px;
  img {
    height: 100%;
  }
`;

export const EmphData = styled(Box)`
  color: ${({ theme }) => theme.colors.emph};
  font-size: 32px;
  font-weight: 700;
  line-height: 1.08em;
`;

export const Label = styled.span`
  display: inline-block;
  min-width: 86px;
  margin-right: 23px;
  color: ${({ theme }) => theme.colors.grey};
  line-height: 1.5em;
  font-weight: 500;
  white-space: nowrap;
`;

export const BadgeStyled = styled(Box)`
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.backgrounds.primary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5em;
`;
