import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InfoOutlineIcon, SettingsIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, VStack, Link, Text } from '@chakra-ui/react';
import PLACEHOLDER from '@assets/images/suppliers/placeholder.png';
import {
  InfoBoxWrapper,
  Label,
  LogoWrapper,
  PaymentButton,
  SettingsButton,
} from '@components/InfoBoxDetailed/style';
import routes from '@routes';

const InfoBoxDetailed = ({
  company,
  companyAddress,
  description,
  email,
  id,
  location,
  logo,
  registeredAddress,
}) => (
  <InfoBoxWrapper>
    <HStack
      spacing="20px"
      justify={['center', 'center', 'space-between']}
      alignItems="top"
    >
      <LogoWrapper>
        <img src={logo || PLACEHOLDER} alt="" />
      </LogoWrapper>
      <VStack spacing="0" alignItems="end">
        <SettingsButton
          as={RouterLink}
          to={routes.settings}
          color="aqua.400"
          _hover={{ color: 'aqua.300' }}
          variant="Link"
          fontSize="18px"
          fontFamily="'Nunito', sans-serif"
          rightIcon={<SettingsIcon fontSize={24} />}
          data-id={id}
        >
          Preferences
        </SettingsButton>
        <PaymentButton
          as={RouterLink}
          to={routes.payment}
          color="aqua.400"
          _hover={{ color: 'aqua.300' }}
          variant="Link"
          fontSize="18px"
          fontFamily="'Nunito', sans-serif"
          rightIcon={<InfoOutlineIcon fontSize={24} />}
        >
          Payment
        </PaymentButton>
      </VStack>
    </HStack>
    {company && (
      <Text
        fontSize="26px"
        fontWeight="600"
        lineHeight="1.22em"
        fontFamily="'Nunito', sans-serif"
        mt={['32px', '32px', '40px']}
      >
        {company}
      </Text>
    )}
    {location && <Text lineHeight="1.5em">{location}</Text>}
    <HStack
      mt="26px"
      spacing={[0, 0, '68px']}
      justify={['normal', 'normal', 'space-between']}
      alignItems="top"
      flexWrap={['wrap', 'wrap', 'nowrap']}
    >
      <Box w={['100%', '100%', 'auto', '63%']} mb={['20px', '20px', 0]}>
        {description && <Text lineHeight="1.5em">{description}</Text>}
      </Box>
      <Box w={['100%', '100%', 'auto']}>
        {companyAddress && (
          <Text lineHeight="1.5em">
            <Label>Company Address:</Label>
            {companyAddress}
          </Text>
        )}
        {registeredAddress && (
          <Text lineHeight="1.5em">
            <Label>Registered Address:</Label>
            {registeredAddress}
          </Text>
        )}
        {email.length > 0 && (
          <Box mt="30px">
            {Array.isArray(email) ? (
              email.map((eml, i) => (
                <Button
                  key={`email-${i}`}
                  as={Link}
                  href={`mailto:${eml}`}
                  color="aqua.400"
                  _hover={{ color: 'aqua.300' }}
                  variant="Link"
                  p="0"
                  display="block"
                  height="auto"
                  lineHeight="1.5em"
                  whiteSpace="break-spaces"
                >
                  {eml}
                </Button>
              ))
            ) : (
              <Button
                as={Link}
                href={`mailto:${email}`}
                color="aqua.400"
                _hover={{ color: 'aqua.300' }}
                variant="Link"
                p="0"
                display="block"
                height="auto"
                lineHeight="1.5em"
                whiteSpace="break-spaces"
              >
                {email}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </HStack>
  </InfoBoxWrapper>
);

InfoBoxDetailed.defaultProps = {
  company: null,
  companyAddress: null,
  description: null,
  email: null,
  location: null,
  logo: null,
  registeredAddress: null,
};

InfoBoxDetailed.propTypes = {
  company: PropTypes.string,
  companyAddress: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  id: PropTypes.number.isRequired,
  location: PropTypes.string,
  logo: PropTypes.string,
  registeredAddress: PropTypes.string,
};

export default InfoBoxDetailed;
