import { makeAutoObservable, runInAction } from 'mobx';
import { APIRoutes, API } from '@app/api';
import toast from '@utils/toast';

export class ContactStore {
  constructor() {
    makeAutoObservable(this);
  }

  contactUsForm = {
    isLoading: false,
    successMsg: '',
    errors: null,
  };

  connectAuthUserWithSupplierForm = { isLoading: false };

  connectAnonymousUserWithSupplierForm = {
    isLoading: false,
    isSendingSuccessful: false,
  };

  onContactUsSubmit = async (formData, setError) => {
    try {
      this.contactUsForm.isLoading = true;

      await API.post(APIRoutes.CONTACT_US, formData);

      runInAction(() => {
        this.contactUsForm.successMsg = 'Message has been sent.';
      });
    } catch (e) {
      setError('global', {
        type: 'string',
        message:
          'Sorry, the message could not be sent. Please try again later.',
      });
    } finally {
      runInAction(() => {
        this.contactUsForm.isLoading = false;
      });
    }
  };

  onConnectAuthBuyerWithSupplier = async supplierSlug => {
    try {
      this.connectAuthUserWithSupplierForm.isLoading = true;

      const {
        data: { status },
      } = await API.post(APIRoutes.CONNECT_BUYER_AND_SUPPLIER(supplierSlug));

      if (status === 'success') {
        toast({
          title: 'Success',
          description: 'Your contact request has been sent to the Supplier',
          status: 'success',
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description:
          'There was an error while trying to establish the connection with supplier. Please try again later.',
        status: 'error',
      });
    } finally {
      runInAction(() => {
        this.connectAuthUserWithSupplierForm.isLoading = false;
      });
    }
  };

  onConnectAnonymousBuyerWithSupplier = async (
    formData,
    selectedSlug,
    uuid,
  ) => {
    try {
      this.connectAnonymousUserWithSupplierForm.isLoading = true;

      const {
        data: { status },
      } = await API.post(APIRoutes.CONNECT_BUYER_AND_SUPPLIER(selectedSlug), {
        buyer: {
          uuid,
          email: formData.email,
        },
      });

      if (status === 'success') {
        toast({
          title: 'Success',
          description: 'Your contact request has been sent to the Supplier',
          status: 'success',
        });
        runInAction(() => {
          this.connectAnonymousUserWithSupplierForm.isSendingSuccessful = true;
        });
      }
    } catch (e) {
      toast({
        title: 'Error',
        description:
          'There was an error while trying to establish the connection with supplier. Please try again later.',
        status: 'error',
      });
    } finally {
      runInAction(() => {
        this.connectAnonymousUserWithSupplierForm.isLoading = false;
      });
    }
  };
}

export default new ContactStore();
