/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';

import { FieldWrapper, Title, Asterix } from '@components/Form/style';
import {
  InputWrapper,
  StyledButton,
  StyledFilename,
  // StyledInput,
  InputLabel,
} from '@components/Form/FileInput/style';

const FileInput = ({
  title,
  register,
  unregister,
  rules,
  name,
  errors,
  required,
  sx,
  fileList,
  ...rest
}) => {
  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  const fieldId = useMemo(() => uniqueId(`file_input_${name}_`), []);

  const file = fileList && fileList[0];

  return (
    <FieldWrapper sx={sx}>
      {title && (
        <Title>
          {title} {required && <Asterix>*</Asterix>}
        </Title>
      )}
      <InputWrapper title={file?.name || 'Upload file'}>
        <InputLabel htmlFor={fieldId}>
          <StyledButton>Browse</StyledButton>
          <StyledFilename>{file?.name || '...'}</StyledFilename>
        </InputLabel>
        <input
          {...{
            id: fieldId,
            type: 'file',
            ...register(name, {
              ...(required && { required: 'Required' }),
              ...rules,
            }),
            ...rest,
          }}
        />
      </InputWrapper>
    </FieldWrapper>
  );
};

FileInput.defaultProps = {
  required: false,
  errors: {},
  title: undefined,
  rules: {},
  sx: {},
  unregister: () => {},
  fileList: null,
};

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func,
  required: PropTypes.bool,
  errors: PropTypes.object,
  rules: PropTypes.object,
  sx: PropTypes.object,
  title: PropTypes.string,
  fileList: PropTypes.object,
};

export default FileInput;
