import styled from '@emotion/styled/macro';
import { Box, Flex } from '@chakra-ui/react';
import BANNERARROWS from '@assets/images/shapes/main-arrows.svg';
import { breakpoint } from '@styles/breakpoints';
import { PageSection } from '@styles/globalStyles';

export const Container = styled(PageSection)`
  display: flex;
  justify-content: space-between;
  padding-top: 104px;
  @media ${breakpoint.md} {
    padding-top: 30px;
    flex-wrap: wrap;
  }
`;

export const ContentLeft = styled(Box)`
  width: 51%;
  font-size: 18px;
  line-height: 1.5em;
  @media ${breakpoint.md} {
    width: 100%;
    margin-top: 40px;
  }
`;

export const ContentRight = styled(Box)`
  width: 43%;
  @media ${breakpoint.md} {
    width: 100%;
    padding: 60px 0 0;
  }
`;

export const ButtonBgWrapper = styled(Flex)`
  position: sticky;
  top: 83px;
  justify-content: center;
  width: 100%;
  min-height: 309px;
  align-items: center;
  background-color: ${({ theme }) => theme.backgrounds.primary};
  background-image: url(${BANNERARROWS});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 1px solid ${({ theme }) => theme.borders.secondary};
  border-radius: 7px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  @media ${breakpoint.md} {
    min-height: 178px;
    background-position: right 21px center;
    background-size: 125%;
  }
  @media ${breakpoint.md} {
    position: static;
    top: auto;
  }
`;
