import styled from '@emotion/styled/macro';
import { Box, Flex } from '@chakra-ui/react';
import isPropValid from '@emotion/is-prop-valid';
import BANNERARROWS from '@assets/images/shapes/main-arrows.svg';

export const PresentaionBox = styled(Box)`
  margin: 0 15px;
  border: 1px solid ${({ theme }) => theme.borders.secondary};
  box-shadow: ${({ theme }) => theme.shadows.primary};
`;

export const PresentaionBoxHeader = styled(Flex, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  flex-direction: column;
  justify-content: center;
  min-height: 111px;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ grey, theme }) =>
    grey ? '#8D8D8D' : theme.backgrounds.primary};
  background-image: url(${BANNERARROWS});
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: right -18px center;
  border: 1px solid ${({ theme }) => theme.borders.secondary};
  text-shadow: ${({ theme }) => theme.shadows.primary};
`;

export const PresentaionBoxContent = styled(Box)`
  padding: 0 40px 30px;
  font-size: 22px;
`;

export const FinePrint = styled(Flex)`
  font-size: 18px;
  flex-direction: column;
  align-items: end;
  color: ${({ theme }) => theme.colors.grey};
`;
