import { history } from '@app/history';
import { ChakraProvider } from '@chakra-ui/react';
import Footer from '@components/Footer';
import NavBar from '@components/NavBar';
import ProtectedRoute from '@components/ProtectedRoute';
import ScrollToTop from '@components/ScrollToTop';
import AboutUsPage from '@containers/AboutUs';
import ContactUsPage from '@containers/ContactUs';
import FaqPage from '@containers/Faq';
import Home from '@containers/Home';
import ImSupplierPage from '@containers/ImSupplier';
import PaymentPage from '@containers/Payment';
import PrivacyPolicyPage from '@containers/PrivacyPolicy';
import ProfilePage from '@containers/Profile';
import ProfileSettingsPage from '@containers/ProfileSettings';
import ResetPasswordPage from '@containers/ResetPassword';
import ResetPasswordConfirmationPage from '@containers/ResetPasswordConfirmation';
import SearchPage from '@containers/Search';
import SignInPage from '@containers/SignIn';
import SignUpPage from '@containers/SignUp';
import SignUpBuyerPage from '@containers/SignUpBuyer';
import TermsAndConditionsPage from '@containers/TermsAndConditions';
import ProcurementService from '@containers/ProcurementService';
import FindAFurnitureDealership from '@containers/FindAFurnitureDealership';
import ThankYou from '@containers/ThankYou';
import styled from '@emotion/styled/macro';
import routes from '@routes';
import GlobalStyle from '@styles/globalStyles';
import theme from '@styles/theme';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { NavLink, Route, Router, Switch } from 'react-router-dom';

const PageWrapper = styled.div`
  padding-top: ${theme.navBar.height.desktop};
  background-color: ${theme.backgrounds.pageContent};
`;

const App = () => (
  <ChakraProvider theme={theme}>
    <GlobalStyle />
    <PageWrapper>
      <Router history={history}>
        <ScrollToTop />
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path={routes.auth.signIn} component={SignInPage} />
          <Route exact path={routes.confirmed} component={SignInPage} />
          <Route path={routes.signUpWithParam} component={SignUpPage} />
          <Route exact path={routes.signUp} component={SignUpPage} />
          <Route exact path={routes.signUpBuyer} component={SignUpBuyerPage} />
          <Route exact path={routes.searchFilter} component={SignUpBuyerPage} />
          <Route
            exact
            path={routes.findAFurnitureDealership}
            component={FindAFurnitureDealership}
          />
          <Route exact path={routes.search} component={SearchPage} />
          <Route
            exact
            path={routes.procurementService}
            component={ProcurementService}
          />
          <ProtectedRoute exact path={routes.payment} component={PaymentPage} />
          <ProtectedRoute exact path={routes.profile} component={ProfilePage} />
          <ProtectedRoute
            exact
            path={routes.settings}
            component={ProfileSettingsPage}
          />
          <Route exact path={routes.imSupplier} component={ImSupplierPage} />
          <Route exact path={routes.aboutUs} component={AboutUsPage} />
          <Route exact path={routes.contactUs} component={ContactUsPage} />
          <Route
            exact
            path={routes.termsAndConditions}
            component={TermsAndConditionsPage}
          />
          <Route
            exact
            path={routes.privacyPolicy}
            component={PrivacyPolicyPage}
          />
          <Route exact path={routes.faq} component={FaqPage} />
          <Route exact path={routes.thankYou} component={ThankYou} />
          <Route
            exact
            path={routes.resetPassword}
            component={ResetPasswordPage}
          />
          <Route
            exact
            path={routes.resetPasswordConfirmation}
            component={ResetPasswordConfirmationPage}
          />
        </Switch>
        <Footer />
        <CookieConsent
          buttonText="OK"
          cookieName="bs-cookie-consent"
          style={{
            background: '#2b7a77',
            alignItems: 'center',
          }}
          buttonStyle={{
            height: '48px',
            minWidth: '150px',
            borderRadius: '4px',
            fontWeight: '500',
            color: '#2b7a77',
            fontSize: '18px',
            backgroundColor: '#fff',
          }}
          contentStyle={{
            lineHeight: '1.5em',
          }}
        >
          We are using cookies to enhance the user experience. You can find out
          more information on how we process your data in our{' '}
          <NavLink
            to={routes.privacyPolicy}
            style={{
              fontWeight: 500,
              textDecoration: 'underline',
            }}
          >
            Privacy Policy
          </NavLink>
          .
        </CookieConsent>
      </Router>
    </PageWrapper>
  </ChakraProvider>
);

export default App;
