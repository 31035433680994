import styled from '@emotion/styled/macro';
import theme from '@styles/theme';
import { Label } from '@components/Form/style';
import { breakpoint } from '@styles/breakpoints';

export const StyledText = styled.span``;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #8d8d8d;
  transition: all 0.2s;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const StyledInput = styled(Label)`
  position: relative;
  padding-left: 35px;
  padding-top: 1px;
  margin-bottom: 12px;
  margin-right: 36px;
  cursor: pointer;
  user-select: none;

  @media ${breakpoint.md} {
    margin-bottom: 20px;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:checked ~ ${StyledText} {
    color: ${theme.colors.emph};
  }

  &:hover input ~ ${Checkmark} {
    border-color: ${theme.borders.primary};
  }

  & input:checked ~ ${Checkmark} {
    border-color: ${theme.borders.primary};
  }

  & input:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    top: 6px;
    left: 6px;
    bottom: 6px;
    right: 6px;
    border-radius: 50%;
    background-color: ${theme.backgrounds.primary};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;

  @media ${breakpoint.md} {
    flex-direction: column;
  }

  ${StyledText} {
    color: ${({ withError }) => (withError ? theme.colors.error : 'inherit')};
  }
`;
