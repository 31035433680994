/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import theme from '@styles/theme';

const customStyles = {
  control: base => ({
    ...base,
    borderColor: theme.borders.primary,
    borderWidth: 2,
    padding: 12,

    ':hover': {
      borderColor: theme.borders.primary,
    },
  }),
  multiValueLabel: base => ({
    ...base,
    fontSize: '18px',
    padding: '4px 8px',
  }),
};

const Select = ({
  name,
  options,
  control,
  required,
  max,
  sx,
  ...selectProps
}) => (
  <Box mb="60px" sx={sx}>
    <Controller
      {...{
        name,
        control,
        rules: { required },
        render: ({ field: { value, onChange, ...rest } }) => (
          <ReactSelect
            {...rest}
            value={options.filter(
              ({ value: val }) => value && value.includes(val.toString()),
            )}
            onChange={e => {
              onChange(e.map(({ value: val }) => val.toString()));
            }}
            options={options}
            styles={customStyles}
            isOptionDisabled={() =>
              max ? value && value.length >= max : false
            }
            {...selectProps}
          />
        ),
      }}
    />
  </Box>
);

Select.defaultProps = {
  options: [],
  required: false,
  sx: undefined,
  max: undefined,
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  sx: PropTypes.object,
  max: PropTypes.number,
};

export default Select;
