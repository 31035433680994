import React from 'react';
import PropTypes from 'prop-types';
import { Text, VisuallyHidden } from '@chakra-ui/react';
import { Label, SimpleBoxWrapper } from '@components/SimpleBox/style';

const SimpleBox = ({ id, params, queryString, title }) => (
  <SimpleBoxWrapper>
    <VisuallyHidden>id: {id}</VisuallyHidden>
    <VisuallyHidden>title: {title}</VisuallyHidden>
    <VisuallyHidden>queryString: {queryString}</VisuallyHidden>
    {Object.keys(params).map(
      (p, index) =>
        (typeof params[p] === 'string' || typeof params[p] === 'number') && (
          <Text fontSize="16px" key={`param-${p}-${index}`}>
            <Label>{p === 'q' ? 'Search phrase' : p}:</Label>
            {params[p]}
          </Text>
        ),
    )}
  </SimpleBoxWrapper>
);

SimpleBox.defaultProps = {
  params: null,
  queryString: null,
  title: null,
};

SimpleBox.propTypes = {
  id: PropTypes.number.isRequired,
  params: PropTypes.object,
  queryString: PropTypes.string,
  title: PropTypes.string,
};

export default SimpleBox;
