import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import Banner from '@components/Banner';
import ProfileSupplier from '@containers/Profile/components/ProfileSupplier';
import ProfileBuyer from '@containers/Profile/components/ProfileBuyer';
import routes from '@routes';

const Profile = ({
  authStore: {
    user: { address, buyer, email, supplier, userType },
  },
}) => {
  if (!buyer && !supplier) {
    return <Redirect to={routes.settings} />;
  }

  return (
    <>
      <Banner title={`I'm a ${userType || 'user'}`} />

      {userType === 'supplier' && supplier && (
        <ProfileSupplier address={address} email={email} supplier={supplier} />
      )}
      {userType === 'buyer' && buyer && (
        <ProfileBuyer buyer={buyer} email={email} />
      )}
    </>
  );
};

Profile.propTypes = {
  authStore: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
};

export default inject('authStore')(observer(Profile));
