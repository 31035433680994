export const ABOUTUS = [
  {
    first: true,
    contents: [
      {
        paragraphs: [
          `If you are searching for a new office or education furniture supplier, we’ll point you in the right direction. BuyerSupplier matches you
           with the best furniture suppliers based on your requirements.`,
          `We’re free to use and you’re welcome to either construct your own tender lists or request as many quotes from suppliers as you would
           like.`,
        ],
        bold: '',
      },
    ],
    contentsRight: [
      {
        image: 'about1',
      },
    ],
    arrow: true,
  },
  {
    even: true,
    contents: [
      {
        header: 'Founders',
        paragraphs: [
          `Founded by industry buyers who were finding it a frustrating process to find suppliers that met their exact needs. Over their careers the
           founders have bought many hundreds of millions of pounds of office furniture and facilities services for large corporate organisations.
           They have found a rigorous simple procurement process helps Buyers save money and achieve better value. Equally they recognise it is
           important that the procurement process itself is good value.`,
          `When looking for the best suppliers in the past we tried using listings companies and word of mouth. Listings companies only differentiated
           the supplier by location, failing to provide an assessment on size, areas of expertise and to what extent they may be capable of meeting
           our requirements. Word of mouth and recommendation from peers was good but sometimes relevant companies were not captured that should have
           been on our tender list.`,
          `We know other buyers share similar frustrations and our aim is to help buyers make better-informed decisions when choosing their furniture
           suppliers.`,
        ],
      },
    ],
    contentsRight: [
      {
        image: 'about2',
      },
    ],
    arrow: true,
  },
  {
    contents: [
      {
        header: 'Your personal data, privacy and security',
        link: {
          textBefore: `We take your privacy seriously and our privacy statement and security statement can be found in our`,
          text: `terms and conditions`,
          url: '/terms-and-conditions',
        },
      },
    ],
    contentsRight: [
      {
        image: 'about3',
      },
    ],
  },
];
