import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Image, useMediaQuery } from '@chakra-ui/react';
import BANNER from '@assets/images/banners/homepage.png';
import RICSLogo from '@assets/images/logos/RICS.svg';
import NewBanner from '@components/Banner/newBanner';
import CompaniesCarousel from '@components/CompaniesCarousel';
import ServicesCarousel from '@components/ServicesCarousel';

import {
  DescriptionSectionBox,
  Title,
  Content,
  TitleUnderscore,
} from '@containers/Home/style';
import useBreakpoint from '@hooks/useBreakpoint';
import routes from '@routes';
import { NewBannerTitle } from '@components/Banner/style';

const Home = () => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const [isSmallerMobile] = useMediaQuery('(max-width: 550px)');

  return (
    <>
      <NewBanner
        size="large"
        title={
          <NewBannerTitle>
            simplifying <span>facilities</span> management procurement
          </NewBannerTitle>
        }
        image={BANNER}
        subtitle="Efficient procurement solutions that improve the bottom line."
        button={null}
      />

      <DescriptionSectionBox pb="120px" pt="120px">
        <Title align="left">
          Helping you buy facilities management services
        </Title>
        <Content maxW="1000px">
          We are a collective of seasoned procurement professionals drawn from
          major private and public sector organisations. We hate waste and
          unnecessary cost and offer a fixed low cost service to procure FM
          related services.
        </Content>
        <Flex
          justifyContent="space-between"
          flexDirection={isMobile ? 'column' : 'row'}
          pt={isMobile ? '48px' : 0}
          sx={{ gap: '20px' }}
        >
          <Button
            as={Link}
            to={routes.procurementService}
            flex={1}
            bg="#4C9F81"
            minH="64px"
            height="100%"
            w="100%"
            maxW={isMobile ? '100%' : '432px'}
            fontSize="24px"
            color="#fff"
            fontWeight="600"
          >
            Find out more
          </Button>

          <Button
            as={Link}
            to={{
              pathname: 'https://calendly.com/buyersupplier/',
            }}
            target="_blank"
            rel="noopener noreferrer"
            flex={1}
            variant="outline"
            height="100%"
            minH="64px"
            maxW={isMobile ? '100%' : '432px'}
            w="100%"
            fontSize="24px"
            color="#4C9F81"
            borderColor="#4C9F81"
            align="center"
            whiteSpace="pre-wrap"
            mt={isMobile ? '20px' : 0}
            fontWeight="600"
          >
            Book a free introductory meeting
          </Button>
        </Flex>
      </DescriptionSectionBox>

      <Box borderTop="1px solid #f1f1f1">
        <DescriptionSectionBox pb="40px" pt="120px">
          <Title align="center">Typical services we procure</Title>
          <TitleUnderscore />

          <ServicesCarousel />
        </DescriptionSectionBox>
      </Box>

      <Box bg="#def2f1">
        <DescriptionSectionBox pb="40px" pt="120px">
          <Title align="center">Excellent Procurement Experience</Title>
          <TitleUnderscore />

          <Content maxW="1100px">
            We have extensive Public and Private Sector Procurement experience
            including Government Departments, the NHS, Universities, Local
            Authorities, Technology Companies, Solicitors, Pharmaceutical
            Companies, Manufacturing, Finance and many others.
          </Content>
          <CompaniesCarousel />
        </DescriptionSectionBox>
      </Box>

      <DescriptionSectionBox pb="40px" pt="120px">
        <Title align="center">Independent advice</Title>
        <TitleUnderscore />
        <Content maxW="1150px">
          We are not affiliated to any Suppliers, which means that we provide
          objective independent advice for the Buyer. Our extensive
          understanding of the Facilities Management market means that we can
          help our clients tender to the organisations that best align with
          their needs
        </Content>
      </DescriptionSectionBox>

      <Box bg="#def2f1">
        <DescriptionSectionBox pb="40px" pt="120px">
          <Flex
            justifyContent={isMobile ? 'space-between' : 'center'}
            gap="32px"
            flexDirection={isSmallerMobile ? 'column' : 'row'}
          >
            <Box flex={1} ml={isMobile ? '0px' : '145px'}>
              <Title align="center">RICS Accredited</Title>
              <TitleUnderscore />
            </Box>
            <Image
              src={RICSLogo}
              alt="RICS"
              alignSelf="flex-start"
              margin={isSmallerMobile ? '0 auto' : '0'}
            />
          </Flex>

          <Content maxW="1150px">
            Landmark and Associates, an RICS accredited company and the owner of
            BuyerSupplier, will manage all BuyerSupplier procurement. Landmark
            have supported the procurement of over £1 billion worth of
            facilities services with clients spanning the public and private
            sectors
          </Content>
        </DescriptionSectionBox>
      </Box>

      <DescriptionSectionBox pb="40px" pt="120px">
        <Title align="center">
          Free, easy to use supplier matching service
        </Title>
        <TitleUnderscore />

        <Content maxW="1060px">
          If you are looking to independently procure your own Suppliers use our
          matching solution to help you find the most appropriate Office
          Furniture Dealerships; this service is free and simple to use.
        </Content>

        <Button
          as={Link}
          to={routes.findAFurnitureDealership}
          flex={1}
          minW="248px"
          bg="#4C9F81"
          minH="60px"
          fontSize="24px"
          color="#fff"
          borderColor="#4C9F81"
          maxW="432px"
          width="100%"
          mt={isMobile ? '20px' : 0}
          fontWeight="600"
        >
          Find a Dealership
        </Button>
      </DescriptionSectionBox>
    </>
  );
};

export default Home;
