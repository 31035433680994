import { Button, Flex } from '@chakra-ui/react';
import FormError from '@components/Form/FormMessage';
import TextField from '@components/Form/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';

const ResetPasswordForm = ({ onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const inputProps = {
    register,
    errors,
    required: true,
    sx: {
      marginBottom: '40px',
    },
  };

  const onSubmitHandler = e => {
    clearErrors('general');
    handleSubmit(data => onSubmit(data, setError))(e);
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Flex width="full" alignItems="center" flexDirection="column">
        <FormError message={errors.general?.message} />
        <TextField label="E-mail" name="email" {...inputProps} />
        <Button type="submit" w="full" minH="56px" isLoading={isLoading}>
          Send
        </Button>
      </Flex>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
