import styled from '@emotion/styled/macro';
import { Box } from '@chakra-ui/react';
import theme from '@styles/theme';

export const Mandatory = styled.span`
  color: red;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 8px;
  margin-right: ${({ withTooltip }) => (withTooltip ? '40px' : 'unset')};
  max-width: ${({ maxW }) => maxW || 'unset'};
  background: white;
`;

export const Input = styled.input`
  width: 100%;
  padding: 18px;
  border: none;
  background: none;
  position: relative;
  outline: none;
  font-size: 18px;
  margin: ${({ as }) => (as === 'textarea' ? '6px 0' : '0')};
  ${({ as }) => as === 'textarea' && 'line-height: 1.4em; padding: 17px 18px;'};
`;

export const Fieldset = styled.fieldset`
  position: absolute;
  top: -8px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  color: ${({ withError }) =>
    withError ? theme.borders.error : theme.borders.primary};
  border: 2px solid currentColor;

  border-radius: 4px;
  pointer-events: none;

  legend {
    font-size: 15px;
    padding: 0 8px;
    color: currentColor;
  }
`;

export const TooltipWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translate(100%, -50%);
`;
