import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, Flex, HStack, SimpleGrid, Text } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import Preloader from '@components/Preloader';
import SimpleBox from '@components/SimpleBox';
import {
  DetailsContainer,
  EmphContainer,
} from '@containers/Profile/components/ProfileBuyer/style';
import routes from '@routes';
import { PageSection } from '@styles/globalStyles';

const ProfileBuyer = ({
  buyer: { id, name, locations = {} },
  email,
  searchesStore: { searches, getSavedSearches },
}) => {
  useEffect(() => {
    getSavedSearches();
  }, [getSavedSearches]);

  const initials = name
    ? name
        .split(' ')
        .map(n => n[0])
        .join('')
    : '@';

  return (
    <>
      <PageSection>
        <EmphContainer>
          <HStack
            spacing={[0, '20px', '20px']}
            justify={['normal', 'space-between', 'space-between']}
            alignItems="center"
            flexWrap={['wrap', 'nowrap', 'nowrap']}
          >
            <DetailsContainer data-initials={initials}>
              <Text
                fontSize={['22px', '26px']}
                fontWeight="600"
                lineHeight="1.22em"
                fontFamily="'Nunito', sans-serif"
              >
                {name || email}
              </Text>
              {locations.length > 0 && (
                <Text lineHeight="1.5em">
                  {locations.map(el => el.label).join(', ')}
                </Text>
              )}
            </DetailsContainer>
            <Flex direction="column" ml="auto" alignItems="flex-end">
              <Button
                as={Link}
                to={routes.settings}
                color="aqua.400"
                _hover={{ color: 'aqua.300' }}
                variant="Link"
                fontSize="18px"
                lineHeight="1.5em"
                fontFamily="'Nunito', sans-serif"
                rightIcon={<SettingsIcon fontSize={24} />}
                pl={['72px', 0, 0]}
                pr="0"
                data-id={id}
              >
                Preferences
              </Button>
            </Flex>
          </HStack>
        </EmphContainer>
        {searches.data.length > 0 && (
          <>
            <Text fontSize="20px" fontWeight="500">
              My saved searches:
            </Text>
            {searches.isLoading && <Preloader />}
            <SimpleGrid
              columns={[1, 1, 2, 3]}
              rowGap="30px"
              ml="-15px"
              mr="-15px"
              mt="17px"
            >
              {searches.data.map((search, index) => (
                <SimpleBox key={`search-${index}`} {...search} />
              ))}
            </SimpleGrid>
          </>
        )}
      </PageSection>
    </>
  );
};

ProfileBuyer.propTypes = {
  buyer: PropTypes.shape({
    id: PropTypes.number,
    locations: PropTypes.array,
    name: PropTypes.string,
  }).isRequired,
  email: PropTypes.string.isRequired,
  searchesStore: PropTypes.shape({
    searches: PropTypes.object.isRequired,
    getSavedSearches: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('searchesStore')(observer(ProfileBuyer));
