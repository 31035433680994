import styled from '@emotion/styled/macro';
import { Box } from '@chakra-ui/react';
import { breakpoint } from '@styles/breakpoints';

const PageSectionCalcWidth = ({ theme }) =>
  `1226px + 2 * ${theme.page.sidePadding}`;

export const Banner = styled(Box)`
  background-image: ${({ image }) => `
   linear-gradient(90deg, rgba(255, 255, 255, 0.9) 49.36%, rgba(255, 255, 255, 0) 82.83%),
    url(${image});`};
  min-height: 380px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .content {
    @media ${breakpoint.md} {
      font-size: 20px;
    }
  }
`;

export const Content = styled(Box)`
  max-width: calc(${PageSectionCalcWidth});
  margin: 0 auto;
  padding: 64px 20px;
  color: black;
`;
