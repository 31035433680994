/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, Tooltip } from '@chakra-ui/react';

import { FieldWrapper, Title, Asterix, Error } from '@components/Form/style';
import {
  InputWrapper,
  Input,
  Fieldset,
  TooltipWrapper,
} from '@components/Form/TextField/style';

const TextField = ({
  label,
  title,
  mandatory,
  register,
  unregister,
  name,
  required,
  rules,
  errors,
  maxW,
  sx,
  tooltip,
  as,
  ...rest
}) => {
  const isTextarea = as === 'textarea';

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  return (
    <FieldWrapper
      width="full"
      sx={sx}
      display={rest.type === 'hidden' ? 'none' : 'flex'}
      {...rest}
    >
      {title && (
        <Title>
          <span>{title}</span>
        </Title>
      )}
      <InputWrapper maxW={maxW} withTooltip={!!tooltip}>
        <Input
          {...{
            as,
            name,
            ...(isTextarea && {
              rows: 4,
            }),
            ...(register &&
              register(name, {
                ...(required && { required: 'Required' }),
                ...rules,
              })),
            ...rest,
          }}
        />
        <Fieldset withError={errors && errors[name]}>
          <legend>
            {label} {required && <Asterix>*</Asterix>}{' '}
          </legend>
        </Fieldset>
        {errors && errors[name] && <Error>{errors[name].message}</Error>}
        {tooltip && (
          <TooltipWrapper>
            <Tooltip
              hasArrow
              label={
                <Box as="span" display="inline-block" p={2}>
                  {tooltip}
                </Box>
              }
            >
              <QuestionOutlineIcon w="20px" h="20px" />
            </Tooltip>
          </TooltipWrapper>
        )}
      </InputWrapper>
    </FieldWrapper>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  maxW: PropTypes.string,
  mandatory: PropTypes.bool,
  box: PropTypes.object,
  register: PropTypes.func,
  unregister: PropTypes.func,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rules: PropTypes.object,
  errors: PropTypes.object,
  sx: PropTypes.object,
  tooltip: PropTypes.string,
  as: PropTypes.oneOf(['input', 'textarea']),
};

TextField.defaultProps = {
  title: undefined,
  mandatory: false,
  box: undefined,
  register: undefined,
  required: undefined,
  maxW: undefined,
  rules: {},
  errors: {},
  sx: undefined,
  tooltip: undefined,
  as: 'input',
  unregister: () => {},
};

export default TextField;
