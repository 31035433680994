import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Text } from '@chakra-ui/react';
import theme from '@styles/theme';

export const FieldWrapper = props => (
  <Flex position="relative" flex={1} flexDir="column" mb="60px" {...props} />
);

export const Title = props => <Text fontWeight="500" mb="24px" {...props} />;

export const Label = styled.label`
  margin-right: 10px;
  display: block;
  margin-bottom: 5px;

  font-weight: 400;
  line-height: 1.4;
  font-size: 18px;
  font-weight: 500;
`;

export const Asterix = styled.span`
  color: red;
`;

export const Error = styled.span`
  color: ${theme.colors.error};
  font-size: 12px;
  line-height: 1.6;
  position: absolute;
  display: block;
  text-align: right;
  width: 100%;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const InputIndicatorWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const FormLink = styled(Link)`
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;
