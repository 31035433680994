/* eslint-disable arrow-body-style */
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import { Box, Tooltip } from '@chakra-ui/react';
import { Asterix, FieldWrapper, Title } from '@components/Form/style';

import {
  StyledText,
  StyledInput,
  InputWrapper,
  Checkmark,
} from '@components/Form/RadioGroup/style';

const RadioGroup = ({
  title,
  options,
  name,
  register,
  unregister,
  required,
  rules,
  errors,
  sx,
  ...rest
}) => {
  const fieldId = useMemo(() => uniqueId(`input_${name}_`), []);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  return (
    <FieldWrapper sx={sx}>
      {title && (
        <Title>
          {title} {required && <Asterix>*</Asterix>}
        </Title>
      )}
      <InputWrapper withError={errors[name] ? 1 : 0}>
        {options.map((option, index) => {
          const label = typeof option === 'string' ? option : option.label;
          const value = typeof option === 'string' ? option : option.value;
          const tooltip = typeof option === 'string' ? null : option.tooltip;

          return (
            <StyledInput
              key={`${fieldId}-${index}`}
              htmlFor={`${fieldId}-${index}`}
            >
              <input
                type="radio"
                id={`${fieldId}-${index}`}
                value={value}
                {...register(name, {
                  ...(required && { required: 'Required' }),
                  ...rules,
                })}
                {...rest}
              />

              <Tooltip
                isDisabled={!tooltip}
                hasArrow
                label={
                  <Box as="span" display="inline-block" p={2}>
                    {tooltip}
                  </Box>
                }
              >
                <StyledText>{label}</StyledText>
              </Tooltip>

              <Checkmark />
            </StyledInput>
          );
        })}
      </InputWrapper>
    </FieldWrapper>
  );
};

RadioGroup.defaultProps = {
  options: [],
  required: false,
  errors: {},
  title: undefined,
  rules: {},
  sx: {},
  unregister: () => {},
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
        ]),
        label: PropTypes.string,
        tooltip: PropTypes.string,
      }),
    ]),
  ),
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func,
  required: PropTypes.bool,
  errors: PropTypes.object,
  rules: PropTypes.object,
  sx: PropTypes.object,
  title: PropTypes.string,
};

export default RadioGroup;
