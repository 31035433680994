import { createStandaloneToast } from '@chakra-ui/react';
import theme from '@styles/theme';

const standaloneToast = createStandaloneToast(theme);
const toast = params =>
  standaloneToast({
    isClosable: true,
    position: 'top',
    ...params,
  });

export default toast;
