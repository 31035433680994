import styled from '@emotion/styled/macro';
import { breakpoint } from '@styles/breakpoints';
import { PageSection } from '@styles/globalStyles';
import { Box } from '@chakra-ui/react';

export const Container = styled(PageSection)`
  margin-top: 52px;
  @media ${breakpoint.xl} {
    margin-top: 52px;
  }
`;

export const EmphContainer = styled(Box)`
  justify-content: space-between;
  background-color: ${({ theme }) => theme.backgrounds.secondary};
  margin-top: 52px;
  margin-bottom: 34px;
  padding: 57px 58px 52px 48px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  @media ${breakpoint.md} {
    margin: 30px 0 24px;
    padding: 35px 30px 26px;
  }
  @media ${breakpoint.xs} {
    padding: 30px 16px 16px;
  }
`;

export const DetailsContainer = styled(Box)`
  position: relative;
  padding-left: 98px;
  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 74px;
    height: 74px;
    content: attr(data-initials);
    background-color: ${({ theme }) => theme.backgrounds.pageContent};
    border: 2px solid ${({ theme }) => theme.colors.emph};
    border-radius: 50%;
    font-size: 26px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 74px;
    @media ${breakpoint.md} {
      width: 58px;
      height: 58px;
      font-size: 20px;
      line-height: 58px;
    }
  }
  @media ${breakpoint.md} {
    width: 100%;
    padding-left: 72px;
    margin-bottom: 8px;
  }
`;
