import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import theme from '@styles/theme';

const FormMessage = ({ message, state, ...rest }) => {
  const errorProps = {
    background: theme.backgrounds.error,
    color: theme.colors.error,
    borderColor: theme.borders.error,
  };

  const successProps = {
    background: theme.colors.aqua[100],
    color: theme.colors.aqua[500],
    borderColor: theme.colors.aqua[500],
  };

  const messageProps = state === 'success' ? successProps : errorProps;

  return message ? (
    <Box
      sx={{
        width: '100%',
        padding: '15px',
        borderRadius: '4px',
        borderWidth: '2px',
        borderStyle: 'solid',
        marginBottom: '40px',
        fontSize: '1rem',
        textAlign: 'center',
      }}
      {...messageProps}
      {...rest}
    >
      {message}
    </Box>
  ) : null;
};

FormMessage.propTypes = {
  message: PropTypes.string,
  state: PropTypes.oneOf(['success', 'error']),
};

FormMessage.defaultProps = {
  message: undefined,
  state: 'error',
};

export default FormMessage;
