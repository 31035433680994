import { makeAutoObservable, runInAction } from 'mobx';
import { APIRoutes, API } from '@app/api';

export class StaticsStore {
  constructor() {
    makeAutoObservable(this);
  }

  statics = {
    isLoading: false,
    faq: [],
    tc: [],
    pp: [],
    error: null,
  };

  getFaq = async () => {
    this.statics.isLoading = true;

    try {
      const {
        data: { staticPage },
      } = await API.get(APIRoutes.STATIC_FAQ);
      runInAction(() => {
        this.statics.faq = staticPage;
      });
    } catch (error) {
      runInAction(() => {
        this.statics.error = error;
      });
    } finally {
      runInAction(() => {
        this.statics.isLoading = false;
      });
    }
  };

  getTermsAndConditions = async () => {
    this.statics.isLoading = true;

    try {
      const {
        data: { staticPage },
      } = await API.get(APIRoutes.STATIC_TC);
      runInAction(() => {
        this.statics.tc = staticPage;
      });
    } catch (error) {
      runInAction(() => {
        this.statics.error = error;
      });
    } finally {
      runInAction(() => {
        this.statics.isLoading = false;
      });
    }
  };

  getPrivacyPolicy = async () => {
    this.statics.isLoading = true;

    try {
      const {
        data: { staticPage },
      } = await API.get(APIRoutes.STATIC_PP);
      runInAction(() => {
        this.statics.pp = staticPage;
      });
    } catch (error) {
      runInAction(() => {
        this.statics.error = error;
      });
    } finally {
      runInAction(() => {
        this.statics.isLoading = false;
      });
    }
  };
}

export default new StaticsStore();
