import styled from '@emotion/styled/macro';
import { Box } from '@chakra-ui/react';

export const SimpleBoxWrapper = styled(Box)`
  margin: 0 15px;
  padding: 30px;
  border: 1px solid ${({ theme }) => theme.borders.secondary};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.backgrounds.pageContent};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.primary};
`;

export const Label = styled.span`
  display: inline-block;
  min-width: 132px;
  margin-right: 6px;
  color: ${({ theme }) => theme.colors.grey};
  line-height: 1.5em;
  font-weight: 500;
`;
