import styled from '@emotion/styled/macro';
import theme from '@styles/theme';
import { Box } from '@chakra-ui/react';

export const InputWrapper = styled(Box)`
  position: relative;
  width: fit-content;
  max-width: 100%;

  input {
    display: none;
  }
`;

export const InputLabel = styled.label`
  border-radius: 4px;
  border: 2px solid #8d8d8d;
  max-width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
`;

export const StyledButton = styled.span`
  display: inline-block;
  border-radius: 4px 0px 0px 4px;
  height: 100%;
  margin: -2px;
  padding: 15px 30px;
  background: ${theme.backgrounds.primary};
  color: white;
  font-weight: 500;
  z-index: 1;
`;

export const StyledFilename = styled.span`
  padding: 2px 15px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
