import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { PageSection } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled(Box)`
  width: 100%;
  margin-top: 100px;
  background-color: ${({ theme }) => theme.backgrounds.pageContent};
  border-top: 1px solid ${({ theme }) => theme.borders.tertiary};
  @media ${breakpoint.md} {
    margin-top: 50px;
  }
`;

export const Container = styled(PageSection)`
  justify-content: space-between;
  min-height: ${({ theme }) => theme.footer.height.desktop};
  @media ${breakpoint.md} {
    min-height: ${({ theme }) => theme.footer.height.mobile};
    margin: 0 auto;
    padding-top: 38px;
  }
`;

export const Logo = styled(NavLink)`
  display: flex;
  width: 206px;
  padding-top: 18px;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
  }
`;

export const Navigation = styled(Flex)`
  @media ${breakpoint.md} {
    flex-wrap: wrap;
    margin-bottom: 30px;
    text-align: center;
  }
`;

export const Content = styled(Box)`
  text-align: right;
  @media ${breakpoint.md} {
    margin: 0 auto;
  }
`;

export const NavButton = styled.a`
  margin: 46px 0 11px 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.23em;
  cursor: pointer;
  &:first-of-type {
    margin-left: 0;
  }
  @media ${breakpoint.md} {
    width: 50%;
    margin: 0;
    font-size: 16px;
    line-height: 2em;
  }
`;
