import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image } from '@chakra-ui/react';

const CompanyTile = ({ logo }) => (
  <Box
    borderRadius="4px"
    minH="200px"
    maxW="200px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    boxShadow="0px 4px 4px 4px rgba(43, 122, 119, 0.15)"
    mx="auto"
    bg="#fff"
  >
    <Image padding="25px" src={logo} alt="" />
  </Box>
);

CompanyTile.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default CompanyTile;
