import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Heading,
  List,
  ListItem,
  Flex,
  Text,
  Circle,
} from '@chakra-ui/react';

const Card = ({
  label,
  price,
  subsections,
  additionalLabel,
  additionalSections,
}) => (
  <Box
    flex={1}
    bg="#fff"
    boxShadow="0px 4px 4px 4px rgba(43, 122, 119, 0.15)"
    borderRadius="4px"
    padding="32px 24px"
    display="flex"
    flexDirection="column"
    sx={{
      '&:hover': { transform: 'scale(1.04)' },
      transition: 'transform .3s',
    }}
  >
    <Heading as="h3" size="md" mb="32px" fontFamily="'Roboto', sans-serif">
      {label}
    </Heading>
    <List minHeight="220px">
      {subsections.map(x => (
        <ListItem key={x.id} mb="18px" maxW="285px">
          <Flex sx={{ gap: '12px' }}>
            <Circle size="10px" bg="#2B7A77" mt="5px" />
            <Text
              fontWeight={400}
              lineHeight="21px"
              fontSize="16px"
              dangerouslySetInnerHTML={{ __html: x.content }}
            />
          </Flex>
        </ListItem>
      ))}
    </List>
    <Text align="center" fontWeight={600} my="48px">
      {price}
    </Text>
    <Text
      fontWeight={400}
      lineHeight="21px"
      fontSize="16px"
      mt="10px"
      mb="32px"
      fontFamily="'Roboto', sans-serif"
    >
      {additionalLabel}
    </Text>
    <List mb="62px">
      {additionalSections.map(x => (
        <ListItem key={x.id} mb="18px" maxW="285px">
          <Flex sx={{ gap: '12px' }}>
            <Circle size="10px" bg="#2B7A77" mt="5px" />
            <Text
              fontWeight={400}
              lineHeight="21px"
              fontSize="16px"
              dangerouslySetInnerHTML={{ __html: x.content }}
            />
          </Flex>
        </ListItem>
      ))}
    </List>
  </Box>
);

Card.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  subsections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
  additionalLabel: PropTypes.string.isRequired,
  additionalSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Card;
