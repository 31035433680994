/* eslint-disable camelcase */
/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';

import { API, APIRoutes } from '@app/api';
import toast from '@utils/toast';
import { history } from '@app/history';
import routes from '@routes';
import addObjectToForm from '@utils/addObjectToForm';
import authStore from './authStore';

export class ProfileStore {
  isLoading = false;

  isSubmitting = false;

  formConfig = {};

  uuid = localStorage.getItem('uuid') || null;

  constructor() {
    makeAutoObservable(this);
  }

  getFormConfig = async () => {
    this.isLoading = true;

    try {
      const {
        data: { signupFormConfig },
      } = await API(APIRoutes.FORM_CONFIG);

      runInAction(() => {
        this.formConfig = signupFormConfig;
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.isLoading = false;
    }
  };

  onSubmit = async (type, formData, setError) => {
    const { id, sector_ids, ...restData } = formData;

    if (sector_ids) {
      if (type === 'supplier') {
        formData.sector_ids = sector_ids;
        restData.sector_ids = sector_ids;
      } else {
        formData.sector_ids = [sector_ids];
        restData.sector_ids = [sector_ids];
      }
    }

    if (authStore.isAuthenticated) {
      this.isSubmitting = true;

      let payload;

      if (type === 'supplier') {
        payload = new FormData();

        const { logo, ...restValues } = restData;

        addObjectToForm(restValues, payload, 'supplier');

        if (logo && logo[0]) {
          payload.append('supplier[logo]', logo[0]);
        }
      } else {
        payload = {
          buyer: restData,
        };
      }
      try {
        if (id) {
          await API.put(APIRoutes.EDIT[type](id), payload);
        } else {
          await API.post(APIRoutes.CREATE[type], payload);
        }

        this.handleSuccess();
      } catch (e) {
        if (e.errors) {
          Object.entries(e.errors).forEach(([key, value]) => {
            setError(key, {
              type: 'create',
              message: value[0],
            });
          });
        }
      } finally {
        this.isSubmitting = false;
      }
    } else {
      authStore.setGuest(type, restData);

      this.isSubmitting = true;

      try {
        if (this.uuid) {
          await API.put(APIRoutes.EDIT.buyer(this.uuid), { buyer: restData });
        } else {
          const {
            data: { uuid },
          } = await API.post(APIRoutes.CREATE.buyer, { buyer: restData });
          localStorage.setItem('uuid', uuid);
          this.uuid = uuid;
        }
      } catch (e) {
        if (e.errors) {
          Object.entries(e.errors).forEach(([key, value]) => {
            setError(key, {
              type: 'create',
              message: value[0],
            });
          });
        }
      } finally {
        this.isSubmitting = false;
      }

      history.push(routes.search);
    }
  };

  handleSuccess = () => {
    toast({
      title: 'Success',
      description: 'Your profile has been updated.',
      status: 'success',
    });

    history.push(routes.base);

    authStore.getProfile();
  };
}

export default new ProfileStore();
