export default {
  base: '/',
  signUp: '/register',
  signUpWithParam: '/register/:base64',
  signUpBuyer: '/register/buyer',
  signUpSupplier: '/register/supplier',
  search: '/search',
  searchFilter: '/search/filter',
  imSupplier: '/im-a-supplier',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  faq: '/faq',
  thankYou: '/thank-you',
  settings: '/profile/settings',
  profile: '/profile',
  payment: '/payment',
  procurementService: '/procurement-service',
  findAFurnitureDealership: '/find-a-furniture-dealership',
  auth: {
    signIn: '/login',
  },
  confirmed: '/confirmed',
  resetPassword: '/password-reset',
  resetPasswordConfirmation: '/password/edit/:token',
};
