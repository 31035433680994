import React, { useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Flex } from '@chakra-ui/react';
import InfoBox from '@components/InfoBox';
import { Wrapper } from '@components/InfoBoxCarousel/style';
import Preloader from '@components/Preloader';

const InfoBoxCarousel = ({
  suppliersStore: { hpCarouselSuppliers, getHpCarouselSuppliers },
}) => {
  useEffect(() => {
    getHpCarouselSuppliers();
  }, [getHpCarouselSuppliers]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      {hpCarouselSuppliers.isLoading && <Preloader />}
      {hpCarouselSuppliers.data.length > 3 ? (
        <Slider {...settings}>
          {hpCarouselSuppliers.data.map((sup, index) => (
            <InfoBox key={`supplier-${index}`} {...sup} />
          ))}
        </Slider>
      ) : (
        <Flex justify="center">
          {hpCarouselSuppliers.data.map((sup, index) => (
            <InfoBox key={`supplier-${index}`} {...sup} />
          ))}
        </Flex>
      )}
    </Wrapper>
  );
};

InfoBoxCarousel.propTypes = {
  suppliersStore: PropTypes.shape({
    hpCarouselSuppliers: PropTypes.object.isRequired,
    getHpCarouselSuppliers: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('suppliersStore')(observer(InfoBoxCarousel));
