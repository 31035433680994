import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import MAP from '@assets/images/banners/map.png';
import Banner from '@components/Banner';
import FormMessage from '@components/Form/FormMessage';
import TextField from '@components/Form/TextField';
import { Container, LinkStyled } from '@containers/AboutUs/style';
import {
  ContentLeft,
  ContentRight,
  EmphContainerStyled,
} from '@containers/ContactUs/style';
import routes from '@routes';

function ContactUs({ contactStore: { onContactUsSubmit, contactUsForm } }) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
  } = useForm();

  const inputProps = {
    register,
    errors,
    required: true,
  };

  return (
    <>
      <Banner title="Contact us" />
      <Container>
        <Flex flexWrap="wrap">
          <ContentLeft>
            <Box mb="40px">
              <Text
                fontSize="22px"
                lineHeight="1.22em"
                fontFamily="'Nunito', sans-serif"
              >
                Support Hours
              </Text>
              <Text
                fontSize="32px"
                lineHeight="1.42em"
                fontFamily="'Nunito', sans-serif"
              >
                8:00am - 7:00pm
              </Text>
              <Text
                fontSize="22px"
                lineHeight="1.22em"
                fontFamily="'Nunito', sans-serif"
                color="#adbec0"
              >
                Monday to Friday, excluding bank holidays
              </Text>
            </Box>

            <Box
              width={{
                base: 'full',
                xl: '83%',
              }}
            >
              <form
                onSubmit={handleSubmit(contact =>
                  onContactUsSubmit(contact, setError),
                )}
              >
                <Flex width="full" alignItems="center" flexDirection="column">
                  <FormMessage message={errors.global?.message} />
                  {contactUsForm.successMsg && (
                    <FormMessage
                      message={contactUsForm.successMsg}
                      state="success"
                    />
                  )}
                  <TextField
                    name="email"
                    label="E-mail"
                    type="email"
                    mb="10px"
                    placeholder="Type your E-mail address"
                    lineHeight="18px"
                    {...inputProps}
                  />
                  <TextField
                    name="body"
                    label="Comment"
                    as="textarea"
                    mb="26px"
                    {...inputProps}
                  />
                  <Button
                    w="full"
                    minH="56px"
                    fontSize="21px"
                    type="submit"
                    isLoading={contactUsForm.isLoading || isSubmitting}
                    disabled={!isDirty || isSubmitSuccessful}
                  >
                    Send
                  </Button>
                </Flex>
              </form>
            </Box>

            <Box mt="55px">
              <Text fontSize="18px" lineHeight="1.5em">
                <b>Company Address:</b> Landmark Facilities Management
                Consultancy Ltd.
                <br />
                <b>Registered Address:</b> 74 Bury Street, Ruislip, Middlesex,
                HA4 7TE
                <br />
                Registered in England No. 3904508
                <br />
                <br />
                <LinkStyled to="mailto:support@buyersupplier.co.uk">
                  support@buyersupplier.co.uk
                </LinkStyled>
                <br />
                <LinkStyled to="mailto:sales@buyersupplier.co.uk">
                  sales@buyersupplier.co.uk
                </LinkStyled>
              </Text>
            </Box>
          </ContentLeft>

          <ContentRight>
            <Image
              src={MAP}
              alt="74 Bury Street, Ruislip, Middlesex, HA4 7TE"
            />
            <EmphContainerStyled>
              <h3>Find a specific supplier</h3>
              <Button
                as={Link}
                to={routes.search}
                variant="outline"
                minW="248px"
                minH="56px"
                fontSize="18px"
                lineHeight="1.23em"
                backgroundColor="#fff"
              >
                Find a Supplier
              </Button>
            </EmphContainerStyled>
          </ContentRight>
        </Flex>
      </Container>
    </>
  );
}

ContactUs.propTypes = {
  contactStore: PropTypes.shape({
    onContactUsSubmit: PropTypes.func.isRequired,
    contactUsForm: PropTypes.object.isRequired,
  }).isRequired,
};

export default inject('contactStore')(observer(ContactUs));
