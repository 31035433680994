import { isObject, isArray, isNil } from 'lodash-es';

/**
 * Appends object's not empty key value pair to formData;
 * @param {Object} object - to convert to formData
 * @param {FormData} formData - new FormData()
 * @param {String} keyName - form data key to add
 */
function addObjectToForm(object, formData, keyName) {
  Object.entries(object).forEach(([key, value]) => {
    const appendKey = `${keyName}[${key}]`;

    if (isArray(value)) {
      value.forEach(item => {
        if (isObject(item)) {
          addObjectToForm(item, formData, `${appendKey}[]`);
        } else if (!isNil(value)) {
          formData.append(`${appendKey}[]`, item);
        }
      });
    } else if (isObject(value)) {
      addObjectToForm(value, formData, appendKey);
    } else if (!isNil(value)) {
      formData.append(appendKey, value);
    }
  });
}

export default addObjectToForm;
