import styled from '@emotion/styled/macro';
import { Box, OrderedList, Text } from '@chakra-ui/react';
import { breakpoint } from '@styles/breakpoints';
import { PageSection } from '@styles/globalStyles';

export const StandardText = styled(Text)`
  font-size: 16px;
  line-height: 1.5em;
`;

export const BannerSubtitle = styled.h2`
  margin-top: 20px;
  @media ${breakpoint.md} {
    margin-top: 24px;
  }
`;

export const BannerInfo = styled(Box)`
  margin-top: 14px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  line-height: 1.4em;
  @media ${breakpoint.md} {
    margin-top: 24px;
  }
  a {
    color: ${({ theme }) => theme.colors.emphLight};
    text-decoration: underline;
    transition: ${({ theme }) => theme.transitionColor};
    &:hover {
      color: ${({ theme }) => theme.colors.hover};
    }
  }
`;

export const ThreeColumnsContainer = styled(PageSection)`
  display: flex;
  flex-direction: row;
  padding-top: 90px;
  padding-bottom: 50px;
  gap: 60px;
  @media ${breakpoint.md} {
    flex-direction: column;
    gap: 20px;
    padding-top: 60px;
    padding-right: 37px;
    padding-left: 37px;
    text-align: center;
  }
  @media ${breakpoint.xs} {
    padding-right: 20px;
    padding-left: 20px;
  }
  div:first-of-type h4 {
    margin-top: 0;
  }
`;

export const BoxHeading = styled.h4`
  min-height: 66px;
  margin-bottom: 33px;
  @media ${breakpoint.md} {
    margin-top: 56px;
    margin-bottom: 19px;
  }
`;

export const OrderedListStyled = styled(OrderedList)`
  counter-reset: item;
  list-style-type: none;
  margin-left: 38px;
  @media ${breakpoint.md} {
    margin-left: 0;
  }
`;

export const ListItemStyled = styled(StandardText)`
  position: relative;
  margin-top: 16px;
  @media ${breakpoint.md} {
    font-size: 18px;
    margin-top: 52px;
    &:first-of-type {
      margin-top: 42px;
    }
  }
  &:before {
    position: absolute;
    left: -38px;
    width: 26px;
    height: 26px;
    content: counter(item);
    counter-increment: item;
    color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.emph};
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.9em;
    @media ${breakpoint.md} {
      top: -29px;
      left: 50%;
      margin-left: -13px;
    }
  }
`;

export const CarouselWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.backgrounds.secondary};
`;

export const Carousel = styled(PageSection)`
  padding-top: 88px;
  padding-bottom: 91px;
  @media ${breakpoint.md} {
    padding-top: 67px;
    padding-bottom: 70px;
  }
`;

export const CarouselHeader = styled(Box)`
  text-align: center;
`;

const PageSectionCalcWidth = ({ theme }) =>
  `1144px + 2 * ${theme.page.sidePadding}`;

export const DescriptionSectionBox = styled(Box)`
  padding: 45px 20px;
  max-width: calc(${PageSectionCalcWidth});
  margin: 0 auto;
  font-family: 'Nunito', sans-serif;

  ${({ pb }) => `
    padding-bottom: ${pb}
  `};

  ${({ pt }) => `
    padding-top: ${pt}
  `};

  @media ${breakpoint.lg} {
    ${({ pt }) => `
    padding-top: calc(${pt} - ${pt} * 0.4);
  `}

    ${({ pb }) => `
    padding-bottom: calc(${pb} - ${pb} * 0.4);
  `}
  }
`;

export const Title = styled.h2`
  color: #2b7a77;
  width: 100%;
  font-size: 48px;

  ${({ align }) => `
    text-align: ${align}
  `};
`;

export const TitleUnderscore = styled.div`
  width: 216px;
  height: 5px;
  background-color: #22625f;
  border-radius: 10px;
  margin: 32px auto;
  margin-bottom: 62px;
`;

export const Content = styled.p`
  color: #1d1d1f;
  margin: 32px 0 64px 0;
  line-height: 32px;
  font-size: 24px;
  max-width: 980px;
  font-weight: 600;

  ${({ maxW }) => `
   max-width: ${maxW};
  `};

  @media ${breakpoint.lg} {
    margin: 32px 0 16px 0;
  }
`;
