import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Container,
  TextContainer,
  NewBannerWrapper,
  ImageWrapper,
  FlexWrapper,
  NewBannerSubtitle,
  Hug,
  MobileImage,
  MobileImageWrapper,
  TitleWrapper,
} from '@components/Banner/style';
import { Button } from '@chakra-ui/react';

const NewBanner = ({ size, title, image, subtitle, button }) => (
  <Hug>
    <Container bSize={size} newBanner>
      <TextContainer fullWidth>
        <NewBannerWrapper>
          <FlexWrapper>
            <TitleWrapper>
              {title}
              {subtitle && <NewBannerSubtitle>{subtitle}</NewBannerSubtitle>}
            </TitleWrapper>

            {image && (
              <ImageWrapper>
                <img src={image} alt="" />
              </ImageWrapper>
            )}
          </FlexWrapper>

          {button && (
            <Button
              as={Link}
              to={button.href}
              flex={1}
              variant="ghost"
              minW="248px"
              minH="56px"
              px="80px"
              fontSize="20px"
              fontWeight="500"
              lineHeight="1.23em"
              backgroundColor="#fff"
            >
              {button.label}
            </Button>
          )}
        </NewBannerWrapper>
      </TextContainer>
    </Container>

    <MobileImageWrapper>
      <MobileImage image={image} />
    </MobileImageWrapper>
  </Hug>
);

NewBanner.defaultProps = {
  size: 'small',
  image: '',
  subtitle: '',
  button: { label: '', href: '' },
};

NewBanner.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  image: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.node.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
};

export default NewBanner;
