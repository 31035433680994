/* eslint-disable no-restricted-syntax */
import { snakeCase, isArray, isBoolean } from 'lodash-es';

function toSnakeCase(obj) {
  const snakeCaseObj = {};
  if (obj) {
    for (const key of Object.keys(obj)) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        let value = obj[key];

        if (isArray(value)) {
          value = value.map(el =>
            typeof el === 'number' ? el.toString() : el,
          );
        }

        if (isBoolean(value)) {
          value = value.toString();
        }

        snakeCaseObj[snakeCase(key)] = value;
      }
    }
  }

  return snakeCaseObj;
}

export default toSnakeCase;
