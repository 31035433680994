import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Box, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormError from '@components/Form/FormMessage';
import styled from '@emotion/styled/macro';
import TextField from '@components/Form/TextField';
import routes from '@routes';

const StyledLink = styled(Link)`
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 15px;
`;

const SignInForm = ({ onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const inputProps = {
    register,
    errors,
    required: true,
    sx: {
      marginBottom: '40px',
    },
  };

  const onSubmitHandler = e => {
    clearErrors('general');
    handleSubmit(data => onSubmit(data, setError))(e);
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Flex width="full" alignItems="center" flexDirection="column">
        <FormError message={errors.general?.message} />
        <TextField label="E-mail" name="email" {...inputProps} />
        <Box
          width="full"
          mb={3}
          sx={{
            position: 'relative',
          }}
        >
          <TextField
            label="Password"
            type="password"
            name="password"
            {...inputProps}
          />
          <StyledLink
            as={RouterLink}
            to={routes.resetPassword}
            color="aqua.500"
          >
            Forgot password?
          </StyledLink>
        </Box>
        <Button type="submit" w="full" minH="56px" isLoading={isLoading}>
          Log in
        </Button>
      </Flex>
    </form>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SignInForm;
