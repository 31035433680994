export const PROJECT_SIZE_OPTIONS = [
  '£0-£5k',
  '£5k-£25k',
  '£25k-£100k',
  '£100k-£250k',
  '>£250k',
];

export const PEOPLE_OPTIONS = ['0-5', '5-25', '25-100', '100-250', '>250'];

export const PROJECT_CATEGORY_OPTIONS = [
  {
    label: 'Low Price Point',
    value: 'Low Price Point',
    tooltip: 'Less than £300 for a desk and chair',
  },
  {
    label: 'Medium Price and quality',
    value: 'Medium Price and quality',
    tooltip: 'Between £300 and £600 for a desk and chair',
  },
  {
    label: 'High price and quality',
    value: 'High price and quality',
    tooltip: 'Greater than £600 for a desk and chair',
  },
];

export const PREFERRED_MANUFACTURER_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const INSTALLATION_OPTIONS = [
  '<1 month',
  '1 - 3months',
  '3- 6 months',
  '6-12 months',
  '> 12 months',
];

export const PUBLIC_SECTOR_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const LOCATION_TYPES_OPTIONS = [
  {
    label: 'UK Only (tick if project is more than one location)',
    value: '1',
  },
  {
    label:
      'International (tick if projects delivered to countries outside of the UK)',
    value: '2',
  },
];
