import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Flex, Box, Image } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import OFFICE from '@assets/images/banners/office4.png';
import Banner from '@components/Banner';
import { Container } from '@containers/AboutUs/style';
import { ContentLeft, ContentRight } from '@containers/ContactUs/style';
import useBreakpoint from '@hooks/useBreakpoint';
import { MediumHeading } from '@styles/globalStyles';
import SignUpForm from './components/SignUpForm';

const SignUpPage = ({
  authStore: { signUp, isLoading, isPotentialSupplier, setIsPotentialSupplier },
  profileStore: { uuid },
}) => {
  const { matchBreakpoint, BREAKPOINTS } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const { base64 } = useParams();

  const [isTempPotentialSupplier, setIsTempPotentialSupplier] = useState(false);

  useEffect(() => {
    if (isPotentialSupplier) {
      setIsTempPotentialSupplier(true);
      setIsPotentialSupplier(false);
    }
  }, [isPotentialSupplier]);

  return (
    <>
      <Banner title="Register" />
      <Container>
        <Flex flexWrap="wrap">
          <ContentLeft>
            <MediumHeading mb="34px">Welcome to BuyerSupplier</MediumHeading>
            <Box
              width={{
                base: 'full',
                lg: '95%',
                xl: '85%',
              }}
            >
              <SignUpForm
                onSubmit={signUp}
                isLoading={isLoading}
                uuid={uuid}
                base64={base64}
                isPotentialSupplier={isTempPotentialSupplier}
              />
            </Box>
          </ContentLeft>

          {!isMobile && (
            <ContentRight>
              <Image src={OFFICE} alt="" />
            </ContentRight>
          )}
        </Flex>
      </Container>
    </>
  );
};

SignUpPage.propTypes = {
  authStore: PropTypes.object.isRequired,
  profileStore: PropTypes.object.isRequired,
};

export default inject('authStore', 'profileStore')(observer(SignUpPage));
