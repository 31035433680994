/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import RadioGroup from '@components/Form/RadioGroup';
import CheckboxGroup from '@components/Form/CheckboxGroup';
import Checkbox from '@components/Form/Checkbox';
import { Box, Button, Center, Spinner, Flex } from '@chakra-ui/react';
import { Element } from 'react-scroll';
import {
  STOCK_OPTIONS,
  PRICE_POINT_OPTIONS,
  WORKSTATIONS_OPTIONS,
  PROJECT_VALUE_OPTIONS,
  TURNOVER_OPTIONS,
  LOCATION_TYPES_OPTIONS,
} from '@containers/SignUpSupplier/data';
import TextField from '@components/Form/TextField';
import { FullBgBox } from '@styles/globalStyles';
import FileInput from '@components/Form/FileInput';
import StepIndicator from '@components/Form/StepIndicator';
import { Label, Title } from '@components/Form/style';
import Select from '@components/Form/Select';
import toSnakeCase from '@utils/toSnakeCase';

const SignUpSupplierForm = ({
  authStore: {
    user: { supplier },
  },
  profileStore: {
    isLoading,
    isSubmitting,
    getFormConfig,
    formConfig,
    onSubmit,
  },
}) => {
  useEffect(() => {
    getFormConfig();
  }, []);

  const { logo, ...supplierDefaultValues } = supplier || {};
  if (supplier) {
    supplierDefaultValues.newFurniture = supplier.newFurniture ? 1 : 0;
    supplierDefaultValues.secondHand = supplier.secondHand ? 1 : 0;
  } else {
    supplierDefaultValues.newFurniture = 1;
  }

  const {
    register,
    unregister,
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: toSnakeCase(supplierDefaultValues),
  });

  const inputProps = {
    register,
    errors,
    unregister,
  };

  const textFieldProps = {
    ...inputProps,
    maxW: '697px',
  };

  const textAreaProps = {
    as: 'textarea',
    maxW: '697px',
    ...inputProps,
  };

  const locationIds = watch('location_ids') || [];
  const locationTypes = watch('location_type_ids') || [];

  useEffect(() => {
    if (locationIds && locationIds.length > 0) {
      if (!locationTypes.includes('1')) {
        setValue('location_type_ids', [...locationTypes, '1']);
      }
    }
  }, [locationIds, locationTypes]);

  useEffect(() => {
    if (supplier) {
      supplier.newFurniture = supplier.newFurniture ? 1 : 0;
      supplier.secondHand = supplier.secondHand ? 1 : 0;
    }
  }, [supplier]);

  register('id');

  return (
    <Box mt="80px">
      <StepIndicator numSteps={5} currentStep={0} />

      <Box
        as="form"
        zIndex={-1}
        mr="42px"
        onSubmit={handleSubmit(data => onSubmit('supplier', data, setError))}
      >
        {isLoading && (
          <Center
            {...{
              position: 'fixed',
              background: 'rgba(255,255,255,0.5)',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Spinner />
          </Center>
        )}
        <Element name="step-1">
          <TextField
            {...{
              title: 'What is your Company name?',
              name: 'company',
              label: 'Company',
              placeholder: 'Type your Company Name',
              required: true,
              ...textFieldProps,
            }}
          />
          <TextField
            {...{
              title: 'What is your Company Website address?',
              name: 'website',
              label: 'Website',
              placeholder: 'Type your Website Address',
              required: true,
              ...textFieldProps,
              sx: { marginBottom: '30px' },
            }}
          />
          <TextField
            {...{
              name: 'case_study',
              label: 'Case Study Link',
              placeholder: 'Type your Case Study link',
              ...textFieldProps,
            }}
          />
          <TextField
            {...{
              title: 'Please enter your Company phone number:',
              name: 'phone',
              label: 'Telephone',
              placeholder: 'Please enter your business phone number',
              required: true,
              ...textFieldProps,
            }}
          />
          <TextField
            {...{
              title: 'Please enter your Company address:',
              name: 'address',
              label: 'Address',
              placeholder: 'Type your Address',
              ...textFieldProps,
            }}
          />
          <TextField
            {...{
              title: 'Please enter the postcode for your main Office location:',
              name: 'postcode',
              label: 'Postcode',
              placeholder: 'Type your Postcode',
              required: true,
              ...textFieldProps,
            }}
          />
          <TextField
            {...{
              title: 'What is your Name?',
              name: 'name',
              label: 'Name',
              placeholder: 'Type your Name',
              ...textFieldProps,
            }}
          />
          <TextField
            {...{
              title: 'What is your Position in the Company?',
              name: 'position',
              label: 'Position',
              placeholder: 'Type your Position inside the Company',
              ...textFieldProps,
            }}
          />
          <FileInput
            {...{
              title: 'Please upload your Company logo:',
              name: 'logo',
              accept: 'image/*',
              ...inputProps,
              fileList: watch('logo'),
              sx: {
                maxW: '697px',
              },
            }}
          />
        </Element>
        <Element name="step-2">
          <FullBgBox py="80px" mb="80px">
            <TextField
              {...{
                title:
                  'Please put a brief description of the service you offer:',
                name: 'description',
                label: 'Description',
                ...textAreaProps,
              }}
            />
            <CheckboxGroup
              {...{
                title:
                  'Which furniture sectors have you supported over the past three years?',
                name: 'sector_ids',
                options: formConfig.sectors,
                ...inputProps,
              }}
            />
            <CheckboxGroup
              {...{
                title:
                  'Have you provided the following related services over the past three years?',
                name: 'related_service_ids',
                options: formConfig.relatedServices,
                ...inputProps,
              }}
            />
          </FullBgBox>
        </Element>
        <Element name="step-3">
          <RadioGroup
            {...{
              title:
                'Please tick the value that best reflects your company turnover:',
              name: 'company_turnover',
              required: true,
              options: TURNOVER_OPTIONS,
            }}
            {...inputProps}
          />
          <RadioGroup
            {...{
              title:
                'Please tick the most typical value of projects that your company has undertaken over the past three years:',
              name: 'project_value',
              options: PROJECT_VALUE_OPTIONS,
              required: true,
              ...inputProps,
            }}
          />
          <RadioGroup
            {...{
              title:
                'Please tick the most typical number of workstations provided on each of your projects that your company has undertaken over the past three years:',
              name: 'workstations',
              options: WORKSTATIONS_OPTIONS,
              ...inputProps,
            }}
          />

          <CheckboxGroup
            {...{
              name: 'location_type_ids',
              title:
                'Please tick the regions you have provided a project or part of a project in the past 3 years.',
              options: LOCATION_TYPES_OPTIONS,
              ...inputProps,
            }}
          />

          <CheckboxGroup
            {...{
              name: 'location_ids',
              title: 'Please specify other project locations:',
              options: formConfig.locations,
              isGird: true,
              ...inputProps,
            }}
          />

          <RadioGroup
            {...{
              title:
                'Please categorise the typical price point for your projects:',
              name: 'price_point',
              required: true,
              options: PRICE_POINT_OPTIONS,
            }}
            {...inputProps}
          />
          <Label>
            Please list up to 3 of the main manufacturers that you deal with:
          </Label>
          <Select
            name="manufacturer_ids"
            placeholder="Select manufacturers..."
            isMulti
            max={3}
            control={control}
            options={formConfig.manufacturers}
          />
        </Element>

        <Element name="step-4">
          <FullBgBox py="80px" mb="80px">
            <RadioGroup
              {...{
                title:
                  'How much stock do you carry? Could you provide the following workstation quantities from stock within 2 weeks?',
                name: 'stocks',
                options: STOCK_OPTIONS,
              }}
              {...inputProps}
            />
            <CheckboxGroup
              {...{
                title:
                  'Please tick any of the following services that you have provided on a project in the past 3 years:',
                name: 'service_ids',
                options: formConfig.services,
                direction: 'column',
                isGird: true,
                ...inputProps,
              }}
            />
            <Box position="relative">
              <Title>Please tick the type of furniture you can provide:</Title>
              <Flex>
                <div>
                  <Checkbox
                    {...{
                      name: 'new_furniture',
                      label: 'New furniture',
                      register,
                      errors,
                      ofGroup: true,
                    }}
                  />
                </div>
                <div>
                  <Checkbox
                    {...{
                      name: 'second_hand',
                      label: 'Second hand furniture',
                      register,
                      errors,
                      ofGroup: true,
                    }}
                  />
                </div>
              </Flex>
            </Box>
          </FullBgBox>
        </Element>

        <Element name="step-5">
          <CheckboxGroup
            {...{
              title: 'Are you on any Public Sector Frameworks for Furniture?',
              name: 'public_sector_framework_ids',
              options: formConfig.publicSectorFrameworks,
              ...inputProps,
            }}
          />
          <CheckboxGroup
            {...{
              title: 'Do you have any of the following accreditations?',
              name: 'iso_norm_ids',
              options: formConfig.isoNorms,
              ...inputProps,
            }}
          />

          {/* <Checkbox
            {...{
              name: 'marketing_contest',
              label: 'I want to receive further info',
              register,
              errors,
            }}
          /> */}
        </Element>

        <Box mt={5}>
          <Button
            type="submit"
            size="lg"
            w="340px"
            maxW="100%"
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SignUpSupplierForm.propTypes = {
  profileStore: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

export default observer(SignUpSupplierForm);
