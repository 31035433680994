/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';
import { isArray } from 'lodash-es';
import toast from '@utils/toast';
import {
  API,
  APIRoutes,
  setAuthenticationToken,
  clearAuthenticationToken,
} from '@app/api';
import { history } from '@app/history';
import routes from '@routes';

export class AuthStore {
  isLoading = false;

  isAuthenticating = false;

  isAuthenticated = false;

  user = {};

  guest = {
    buyer: null,
    supplier: null,
  };

  isPotentialSupplier = false;

  constructor() {
    makeAutoObservable(this);
    this.autoSignIn();
  }

  setIsPotentialSupplier = isPotentialSupplier => {
    this.isPotentialSupplier = !!isPotentialSupplier;
  };

  signUp = async (formData, setError, uuid) => {
    this.isLoading = true;

    let payload = {
      user: { ...{ user_type: 'buyer' }, ...formData },
    };

    if (
      this.isPotentialSupplier ||
      formData.base64 ||
      formData.client_type === 'supplier'
    ) {
      delete formData.client_type;
      payload = { user: { ...formData, ...{ user_type: 'supplier' } } };
    } else if (uuid) {
      payload = {
        user: { ...formData, ...{ user_type: 'buyer' } },
        ...{ buyer: { uuid } },
      };
    }

    try {
      await API.post(APIRoutes.SIGN_UP, payload);
      toast({
        title: 'Success',
        description:
          'You have registered successfully. Please check your email inbox (or possibly "Junk") to "Confirm my Account"',
        status: 'success',
      });
      if (uuid) {
        localStorage.removeItem('uuid');
      }
      history.push(routes.base);
    } catch (e) {
      if (e.errors) {
        Object.entries(e.errors).forEach(([key, value]) => {
          setError(key, {
            type: 'auth',
            message: value[0],
          });
        });
      }
    } finally {
      this.isLoading = false;
    }
  };

  signIn = async ({ email, password }, setError) => {
    this.isLoading = true;
    try {
      const {
        headers,
        data: { user },
      } = await API.post(APIRoutes.SIGN_IN, {
        email,
        password,
      });

      const authHeaders = {
        'access-token': headers['access-token'],
        client: headers.client,
        uid: headers.uid,
        expiry: headers.expiry,
        'token-type': headers['token-type'],
      };

      setAuthenticationToken(authHeaders);

      runInAction(() => {
        this.isAuthenticated = true;
        this.user = user;
        this.guest = {
          buyer: null,
          supplier: null,
        };
      });

      if (user.supplier || user.buyer) {
        history.push(routes.base);
      } else {
        history.push(routes.settings);
      }
    } catch (e) {
      if (e.errors) {
        if (isArray(e.errors)) {
          setError('general', {
            type: 'auth',
            message: e.errors[0],
          });
        } else {
          Object.entries(e.errors).forEach(([key, value]) => {
            setError(key, {
              type: 'auth',
              message: value,
            });
          });
        }
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }

    return null;
  };

  autoSignIn = async () => {
    runInAction(() => {
      this.isAuthenticating = true;
    });
    try {
      const AUTH_HEADERS = JSON.parse(
        localStorage.getItem('auth-headers') || null,
      );
      if (AUTH_HEADERS) {
        API.defaults.headers.common = AUTH_HEADERS;
        await this.getProfile();
        runInAction(() => {
          this.isAuthenticated = true;
          this.guest = {
            buyer: null,
            supplier: null,
          };
        });
      }
    } catch (error) {
      runInAction(() => {
        this.isAuthenticated = false;
        localStorage.removeItem('auth-headers');
      });
    } finally {
      runInAction(() => {
        this.isAuthenticating = false;
      });
    }
  };

  resetPassword = async ({ email }, setError) => {
    this.isLoading = true;
    try {
      await API.post(APIRoutes.RESET_PASSWORD, {
        email,
      });

      history.push(routes.base);

      toast({
        title: 'Success',
        description:
          'You have reset your password. Please check your email inbox to set a new one',
        status: 'success',
      });
    } catch (e) {
      if (e.errors) {
        if (isArray(e.errors)) {
          setError('general', {
            type: 'auth',
            message: e.errors[0],
          });
        } else {
          Object.entries(e.errors).forEach(([key, value]) => {
            setError(key, {
              type: 'auth',
              message: value,
            });
          });
        }
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }

    return null;
  };

  resetPasswordConfirmation = async (
    { password, passwordConfirmation, token },
    setError,
  ) => {
    this.isLoading = true;
    try {
      await API.put(APIRoutes.RESET_PASSWORD_CONFIRMATION, {
        password,
        password_confirmation: passwordConfirmation,
        reset_password_token: token,
      });

      history.push(routes.auth.signIn);

      toast({
        title: 'Success',
        description:
          'You have successfully changed your password and can now sign in',
        status: 'success',
      });
    } catch (e) {
      if (e.errors) {
        if (isArray(e.errors)) {
          setError('general', {
            type: 'auth',
            message: e.errors[0],
          });
        } else {
          Object.entries(e.errors).forEach(([key, value]) => {
            setError(key, {
              type: 'auth',
              message: value,
            });
          });
        }
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }

    return null;
  };

  getProfile = async () => {
    try {
      const {
        data: { user },
      } = await API(APIRoutes.PROFILE);
      this.user = user;
    } catch (error) {
      runInAction(() => {
        this.isAuthenticated = false;
        localStorage.removeItem('auth-headers');
      });
    }
  };

  setGuest = (type, data) => {
    this.guest[type] = data;
  };

  logout = async () => {
    try {
      await API.delete(APIRoutes.LOGOUT);
    } catch (error) {
      console.warn(error);
    } finally {
      runInAction(() => {
        clearAuthenticationToken();
        this.clearStore();
        localStorage.removeItem('auth-headers');
      });
    }
  };

  clearStore = () => {
    this.isAuthenticated = false;
    this.user = {};
    this.guest = {
      buyer: null,
      supplier: null,
    };
    this.isLoading = false;
    this.isPotentialSupplier = false;
  };
}

export default new AuthStore();
