import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box } from '@chakra-ui/react';
import Banner from '@components/Banner';
import Preloader from '@components/Preloader';
import { PageSection, StaticContent } from '@styles/globalStyles';

const TermsAndConditions = ({
  staticsStore: { statics, getTermsAndConditions },
}) => {
  useEffect(() => {
    getTermsAndConditions();
  }, [getTermsAndConditions]);

  const createMarkup = () => ({
    __html: statics.tc.body,
  });

  return (
    <>
      <Banner title={statics.tc.title || 'Terms and conditions'} />
      <PageSection mt="40px">
        {statics.tc.subtitle && (
          <Box mb="34px" as="h6">
            {statics.tc.subtitle}
          </Box>
        )}
        {statics.isLoading && <Preloader />}
        <StaticContent dangerouslySetInnerHTML={createMarkup()} />
      </PageSection>
    </>
  );
};

TermsAndConditions.propTypes = {
  staticsStore: PropTypes.shape({
    statics: PropTypes.object.isRequired,
    getTermsAndConditions: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('staticsStore')(observer(TermsAndConditions));
