import service1 from '@assets/images/banners/service1.png';
import service2 from '@assets/images/banners/service2.png';
import service3 from '@assets/images/banners/service3.png';
import service4 from '@assets/images/banners/service4.jpg';
import service5 from '@assets/images/banners/service5.jpg';
import service6 from '@assets/images/banners/service6.jpg';
import service7 from '@assets/images/banners/service7.jpg';
import service8 from '@assets/images/banners/service8.jpg';

export const services = [
  { id: 1, image: service1, label: 'Building Maintance' },
  { id: 2, image: service2, label: 'Cleaning Services' },
  { id: 3, image: service3, label: 'Security Services' },
  { id: 4, image: service4, label: 'Facilties Management' },
  { id: 5, image: service6, label: 'M&E Maintenance' },
  { id: 6, image: service5, label: 'Grounds Maintenance' },
  { id: 7, image: service8, label: 'Office Furniture' },
  { id: 8, image: service7, label: 'Audio Visual' },
];
