import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box } from '@chakra-ui/react';
import Banner from '@components/Banner';
import Preloader from '@components/Preloader';
import { PageSection, StaticContent } from '@styles/globalStyles';

const TermsAndConditions = ({
  staticsStore: { statics, getPrivacyPolicy },
}) => {
  useEffect(() => {
    getPrivacyPolicy();
  }, [getPrivacyPolicy]);

  const createMarkup = () => ({
    __html: statics.pp.body,
  });

  return (
    <>
      <Banner title={statics.pp.title || 'Privacy Policy'} />
      <PageSection mt="40px">
        {statics.pp.subtitle && (
          <Box mb="34px" as="h6">
            {statics.pp.subtitle}
          </Box>
        )}
        {statics.isLoading && <Preloader />}
        <StaticContent dangerouslySetInnerHTML={createMarkup()} />
      </PageSection>
    </>
  );
};

TermsAndConditions.propTypes = {
  staticsStore: PropTypes.shape({
    statics: PropTypes.object.isRequired,
    getPrivacyPolicy: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('staticsStore')(observer(TermsAndConditions));
